import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import notification from "../Toast";
import moment from "moment/moment";
import style from "../../Style/Tool.module.css";
import LoginModel from "../model/LoginModel";
import { useNavigate } from 'react-router-dom';

import HandleGoogle from '../../Firebase/handleGoogleAuth';


export const Comment = ({ el, id, setreview }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rate, setRate] = useState(0);
  const [inputvalue, setinput] = useState("");
  let [reviewData, setReviewData] = useState([]);
  let [initReviewData, setInitReviewData] = useState([]);
  const userData = useSelector((state) => state.userReducer.loginData);
  const [loader, setLoader] = useState(true);
  let [flag, setFlag] = useState(true);

  const [hover, setHover] = useState(0);
  
  // const [loginModelOpen, setloginModelOpen] = useState(false);

  // const handleOpen = () =>{
  //   setloginModelOpen(true)
  // }
  // const handleClose = () =>{
  //   setloginModelOpen(false)
  // }

  const navigate = useNavigate();
const dispatch = useDispatch();

  const handleOpen = async () => {
    if (!userData) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, onOpen);
    } else {
      // If the user is already logged in, just open the modal
      onOpen();
    }
  };

  setreview(reviewData.length);

  const getReview = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API}/data/get/comment/${el._id}`
      );
      const sortedData = res.data.comment.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setReviewData(res.data.comment);
      setInitReviewData(res.data.comment);
    } catch (error) {
      //console.log(error);
    }
  };
  const SubmitReview = async (e) => {
    // Check if the user is logged in
    let token = userData?.data;
    if (!token) {
      // If not logged in, initiate the Google login flow
      await HandleGoogle(dispatch, navigate, null, onOpen);
      return; // Stop execution of the function here
    }
  
    let payload = {
      message: inputvalue,
      rating: rate,
    };
    if (payload?.rating < 1) {
      notification("error", "You have to give at least one star!");
      return;
    }
  
    try {
      // Since the user is logged in, proceed with submitting the review
      axios.post(
        `${process.env.REACT_APP_API}/data/update/${el._id}/add-comment`,
        payload,
        { headers: { token } }
      )
      .then((res) => {
        setLoader(prev => !prev); // Toggle the loader state
        setRate(0);
        setinput("");
        getReview();
        notification("success", "Review submitted successfully!");
      });
    } catch (err) {
      notification("error", "Something went wrong while submitting the review.");
    }
  };

  const handleLike = async (id) => {
    let token = userData.data;
    const updatedLikeCount = reviewData.find((ele) => ele._id === id).likes + 1;

    try {
      await axios
        .patch(
          `${process.env.REACT_APP_API}/data/update/${id}/add-like/comment`,
          { likes: updatedLikeCount },
          { headers: { token } }
        )
        .then((res) => {
          setReviewData((prevReviewData) =>
            prevReviewData.map((ele) =>
              ele._id === id ? { ...ele, likes: updatedLikeCount } : ele
            )
          );
        });
    } catch (error) {
      notification("error", "You have to login first");
    }
  };

  useEffect(() => {
    getReview();
  }, []);

  const handlestar = async (gr) => {
    setRate(gr);

    let payload = { rating: Number(rate) };
    try {
      let token = userData.data;
      await axios.post(`${process.env.REACT_APP_API}/data/rating`, payload, {
        headers: { token },
      });
      setLoader(!loader);
    } catch (err) {
      notification("error", "Something went wrong");
    }
  };

  const ratings = [1, 2, 3, 4, 5];
  const [selectedRating, setSelectedRating] = useState("");
  const [sort, setSort] = useState("");

  const handleChange = (event) => {
    const val = event.target.value;
    setSelectedRating(val);
    if (!val) {
      setReviewData(initReviewData);
    } else {
      let newData = initReviewData.filter((item) => item.rating === val);
      setReviewData(newData);
    }
  };

  const handleSortChange = (event) => {
    const val = event.target.value;
    setSort(val);
    if (!val) {
      setSort(initReviewData);
    } else if (val === "low") {
      const deepCopyReviewData = JSON.parse(JSON.stringify(initReviewData));
      deepCopyReviewData.sort((a, b) => a.rating - b.rating);
      setReviewData(deepCopyReviewData);
    } else {
      const deepCopyReviewData = JSON.parse(JSON.stringify(initReviewData));
      deepCopyReviewData.sort((a, b) => b.rating - a.rating);
      setReviewData(deepCopyReviewData);
    }
  };

  return (
    <Box paddingBlock={7}>
  <Box
   w={{ base: "100%", md: "70%" }}
    border="1px"
    borderColor={useColorModeValue("#e0e0e0", "#444")}
    p="18px"
    borderRadius="10px"
    mt="4"
    //bg={useColorModeValue("white", "gray.600")} // Dark mode compatibility
   
  >
    <Flex direction="column" alignItems="left" >
      <Text mb="5" fontSize="18px" fontWeight="600">
        Leave a reviw
      </Text>
      <Flex gap="2" mb="4">
        {[...Array(5)].map((_, index) => {
          const givenRating = index + 1;
          return (
            <Box
              key={index}
              as="button"
              onClick={() => setRate(givenRating)}
              onMouseEnter={() => setHover(givenRating)}
              onMouseLeave={() => setHover(rate)}
              _focus={{ outline: 'none' }}
            >
              <FaStar
                size="25"
                color={givenRating <= (hover || rate) ? "#ECBA67" : "#e0e0e0"}
                transition="color 0.2s ease-in-out" // Smooth transition for hover effect
              />
            </Box>
          );
        })}
      </Flex>
      <Textarea
        rows={5}
        placeholder="Share your thoughts"
        value={inputvalue}
        bg={useColorModeValue("f8f8f8", "#333333")} // Dark mode compatibility
        onChange={(e) => setinput(e.target.value)}
        mt="2"
        w="100%" // Use the full width of the container
        resize="none" // Disable resizing to maintain the design
        boxShadow="0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)" // Soft shadow for the textarea
       
      />
      <Button
        onClick={SubmitReview}
        w="140px"
        h="45px"
        // colorScheme="blue"
        borderRadius="4px"
        mt="4"
        bg="#1D9BF0"
        color="#F8F8F8"
        // isLoading={submitting} // Use a loading state when submitting
        loadingText="Submitting"
        _hover={{
          transform: "translateY(-2px)", // Slightly lift the button on hover
          boxShadow: "lg", // Increase shadow on hover
        }}
      >
        Submit Review
      </Button>
    </Flex>
  </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap={5}>
            <Text fontSize="110px" fontWeight="600" color="#1D9BF0">
              {el.rating}/5
            </Text>

            <Text fontSize="18px" fontWeight="400" as="h2"lineHeight="24px">
              {reviewData.length} reviews
            </Text>
          </Flex>

          <Box display={{ base: "none", md: "block" }}>
            <Flex gap="7px" mt="5px">
              {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                  <Box key={index}>
                    <FaStar
                      size={12}
                      value={givenRating}
                      color={givenRating <= 5 ? "#ECBA67" : "#e0e0e0"}
                    />
                  </Box>
                );
              })}
            </Flex>
            <Flex gap="7px" mt="5px">
              {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                  <Box key={index}>
                    <FaStar
                      size={12}
                      value={givenRating}
                      color={givenRating <= 4 ? "#ECBA67" : "#e0e0e0"}
                    />
                  </Box>
                );
              })}
            </Flex>
            <Flex gap="7px" mt="5px">
              {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                  <Box key={index}>
                    <FaStar
                      size={12}
                      value={givenRating}
                      color={givenRating <= 3 ? "#ECBA67" : "#e0e0e0"}
                    />
                  </Box>
                );
              })}
            </Flex>
            <Flex gap="7px" mt="5px">
              {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                  <Box key={index}>
                    <FaStar
                      size={12}
                      value={givenRating}
                      color={givenRating <= 2 ? "#ECBA67" : "#e0e0e0"}
                    />
                  </Box>
                );
              })}
            </Flex>
            <Flex gap="7px" mt="5px">
              {[...Array(5)].map((item, index) => {
                const givenRating = index + 1;
                return (
                  <Box key={index}>
                    <FaStar
                      size={12}
                      value={givenRating}
                      color={givenRating === 1 ? "#ECBA67" : "#e0e0e0"}
                    />
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Box
        m="auto"
        border="1px"
        borderColor={useColorModeValue("#e0e0e0", "#444")}
        bg={useColorModeValue("transparent")}
        px="33px"
        py="20px"
        paddingBottom="50px"
        borderRadius="10px"
        mt="50px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)" // Adding lush shadow
        
      >
        <Flex
          className={style.riviewfilter}
          mt="20px"
          mb="20px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="23px" lineHeight="32px" fontWeight="700">
            {reviewData.length} Reviews
          </Text>

          <Flex gap="13px">
            {/* <Select
              border="1px solid #B8B7B7"
              color="#B8B7B7"
              w="111px"
              h="29px"
              placeholder="Sort "
              onChange={handleSortChange}
            >
              <option value={""}>None</option>
              <option value={"low"}>Sort By Low to high</option>
              <option value={"high"}>Sort By High to low</option>
            </Select>
            <Select
              border="1px solid #B8B7B7"
              color="#B8B7B7"
              width="111px"
              height="29px"
              placeholder="Rating"
              value={selectedRating}
              onChange={handleChange}
            >
              <option value={""}>Any rating</option>
              {ratings.map((rating) => (
                <option key={rating} value={rating}>
                  {rating} rating
                </option>
              ))}
            </Select> */}
            {/* <Select
              border="1px solid #B8B7B7"
              color="#B8B7B7"
              w="111px"
              h="29px"
              placeholder="Any rating "
            >
              {" "}
            </Select> */}
          </Flex>
        </Flex>

        {/* maping----------------------------- */}
        <Box mt="25px">
          {reviewData?.map((ele, i) => (
            <Box mt="30px" key={i}>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap="10px">
                  <Avatar src={ele.userID?.image} />

                  <Box>
                    <Text fontSize="15px" fontWeight="600" lineHeight="24px">
                      {ele.userID?.name}
                    </Text>
                  </Box>
                </Flex>

                <Box textAlign="center">
                  <AiFillLike
                    size={23}
                    cursor="pointer"
                    onClick={() => handleLike(ele._id)}
                  />
                  <Text>{ele.likes}</Text>
                </Box>
              </Flex>

              <Flex mt="15px" mb="10px" gap="7px">
                {[...Array(5)].map((item, index) => {
                  const givenRating = index + 1;
                  return (
                    <Box key={index}>
                      <FaStar
                        color={
                          givenRating <= ele.rating ? "#ECBA67" : "#e0e0e0"
                        }
                      />
                    </Box>
                  );
                })}
              </Flex>

              <Text
              fontSize="15px"
              fontWeight="400"
              lineHeight="30px"
               mt="15px"
               letterSpacing="0.5px"
              wordSpacing="1px" // Adjust the value for spacing between words
               >
              {ele.message}
              </Text>
              <Flex
                alignItems="center"
                gap="153px"
                fontSize="12px"
                lineHeight="20px"
                fontWeight="400"
                py={3}
              >
                 <Text>{moment(ele.createdAt).format("MMM Do YYYY")}</Text>
                {/* <Text fontSize="15px" letterSpacing="2px" fontWeight="bold">
                  ...
                </Text> */}
              </Flex>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};


///update/:id/add-like

import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homepageDataLoading } from "../Redux/action";
import { AiOutlineHeart } from "react-icons/ai";
import { Box, Button, Text } from "@chakra-ui/react";
// import LoginModel from "./model/LoginModel";
import HandleGoogle from '../Firebase/handleGoogleAuth';
// import {useNavigate } from "react-router-dom";


export const SpaceLikes = ({ el }) => {
  //console.log(el);
  let [isLikes, setIsLisks] = React.useState(false);
  
  // const [loginModelOpen, setloginModelOpen] = useState(false);
  const handleOpen = () =>{
    // setloginModelOpen(true)
  }
  const handleClose = () =>{
    // setloginModelOpen(false)
  }

  const userData = useSelector((state) => state.userReducer.loginData);

  const dispatch = useDispatch();

  const handleLike = async () => {
    try {
      let token = userData.data;
      if(!token){
        await HandleGoogle(dispatch, handleClose);
        handleOpen();
        return;
      }
      const id = el._id;
      await axios.patch(
        `${process.env.REACT_APP_API}/space/update/${id}/add-like`,
        null,
        { headers: { token } }
      );
      if (isLikes) {
        el.likes--;
        setIsLisks(false);
      } else {
        el.likes++;
        setIsLisks(true);
      }
      dispatch(homepageDataLoading());
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <Box>
       {/* {loginModelOpen? <LoginModel onCloseOuter={handleClose}/> : null} */}
      <Button
        _hover={{ bg: "" }}
        cursor="pointer"
        onClick={handleLike}
        bg={isLikes ? "tomato" : "#1D9BF0"}
        gap="5px"
        color="white"
        fontWeight="600"
        fontSize="16px"
        lineHeight="24px"
      >
        {" "}
        <AiOutlineHeart size={20} /> <Text display={{ base: "none", md: "block" }}>Like</Text> {/* Text hidden on base, shown on md */}
      </Button>
    </Box>
  );
};
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Divider,
  Stack,
  Image,
  Grid,Button,
  SimpleGrid,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { BsQuestionDiamondFill } from "react-icons/bs";
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {useState} from 'react';
import { Spinner } from "@chakra-ui/react";


export const Form3 = ({
  Logo,
  Cover_image,
  Galary_image,
  setLogo,
  setGalary_image,
  setCover_image,
  handlechange,
  Youtube_embed,
}) => {


  const [logoLoading, setLogoLoading] = useState(false);
  const [coverImageLoading, setCoverImageLoading] = useState(false);

  const mandatoryTextColor = useColorModeValue ("lightShadeInLightMode", "lightShadeInDarkMode");

  const { colorMode } = useColorMode();  

  const generateFileName = (prefix, extension) => {
    const date = new Date();
    const dateString = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const randomString = Math.random().toString(36).substring(2, 7);
    return `${prefix}_${dateString}_${randomString}.${extension}`;
  };

  // Function to upload a file to Bunny.net
  const uploadToBunny = async (file, fileName) => {
    const filePath = `/Submit%26Creators/${fileName}`;
    const bunnyUrl = `https://storage.bunnycdn.com/aizones-io${filePath}`;

    try {
      const response = await axios.put(bunnyUrl, file, {
        headers: {
          AccessKey: '0a6936ee-b93a-4142-8db5948ee34f-5c81-45e5',
          'Content-Type': file.type,
        },
      });

      if (response.status === 201) {
        return `https://ai-zones.b-cdn.net${filePath}`;
      } else {
        throw new Error('Failed to upload to Bunny.net');
      }
    } catch (error) {
      console.error('Error uploading to Bunny.net:', error);
      throw error;
    }
  };

  const deleteFromBunny = async (imageUrl) => {
    const filePath = imageUrl.split('ai-zones.b-cdn.net')[1]; // Example logic to extract the file path
    const bunnyUrl = `https://storage.bunnycdn.com/aizones-io${filePath}`;
  
    try {
      const response = await axios.delete(bunnyUrl, {
        headers: {
          AccessKey: '0a6936ee-b93a-4142-8db5948ee34f-5c81-45e5', // Use the correct header and authentication method as per Bunny CDN docs
        },
      });
  
      if (response.status === 200) {
        console.log('Image deleted successfully');
      } else {
        throw new Error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting image from Bunny CDN:', error);
      throw error; // You might want to handle this more gracefully
    }
  };
  

  // Modified addImage function
  const addImage = async (event) => {
    setLogoLoading(true); // Start loading
    const file = event.target.files[0];
    if (file && file.size <= 500 * 1024) { // 500kb
      const fileName = generateFileName('logo', file.name.split('.').pop());
      const uploadedUrl = await uploadToBunny(file, fileName);
      setLogo(uploadedUrl);
      setLogoLoading(false); // End loading
    } else {
      alert('File size exceeds 500kb');
    }
  };

  // Modified addcoverimg function
  const addcoverimg = async (event) => {
    setCoverImageLoading(true); // End loading
    const file = event.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) { // 2mb
      const fileName = generateFileName('cover', file.name.split('.').pop());
      const uploadedUrl = await uploadToBunny(file, fileName);
      setCover_image(uploadedUrl);
      setCoverImageLoading(false); // End loading
    } else {
      alert('File size exceeds 2mb');
    }
  };
  
  // const addGalary_image = async (event, index) => {
  //   const file = event.target.files[0];
  //   if (file && file.size <= 2 * 1024 * 1024) { // Check file size (2MB limit)
  //     const fileName = generateFileName('gallery', file.name.split('.').pop());
  //     const uploadedUrl = await uploadToBunny(file, fileName);
  //     // Create a copy of the current gallery images
  //     let newGalaryImages = [...Galary_image];
  //     // Replace the image at the specific index
  //     newGalaryImages[index] = uploadedUrl;
  //     // Update the state with the new gallery images array
  //     setGalary_image(newGalaryImages);
  //   } else {
  //     alert('File size exceeds 2MB');
  //   }
  // };


  const addGalary_image = async (event, index) => {
    const file = event.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) { // 2MB limit
      // Check if there's an existing image and delete it
      if (Galary_image[index]) {
        await deleteFromBunny(Galary_image[index]);
      }
  
      const fileName = generateFileName('gallery', file.name.split('.').pop());
      const uploadedUrl = await uploadToBunny(file, fileName);
      let newGalaryImages = [...Galary_image];
      newGalaryImages[index] = uploadedUrl;
      setGalary_image(newGalaryImages);
    } else {
      alert('File size exceeds 2MB');
    }
  };


  const removeGalary_image = async (index) => {
    // Optional: Delete the image from Bunny CDN
    if (Galary_image[index]) {
      await deleteFromBunny(Galary_image[index]);
    }
  
    // Update the local state to remove the image
    let newGalaryImages = [...Galary_image];
    newGalaryImages[index] = ""; // Remove the image at the specified index
    setGalary_image(newGalaryImages);
  };
  
  

  return (
    <>
      <Box>
      <Flex alignItems="center" gap="8px">
      Thumbnail
          <Tooltip padding="10px"
            label="Kindly provide the official icon or favicon of your tool for the thumbnail image in your listing. This assists users in swiftly recognizing your tool, while maintaining a uniform appearance on our site. We advise opting for a square image with a minimum resolution of 256x256 pixels. The maximum file size should be 500 KB."
            aria-label=""
          >
            <p>
              <BsQuestionDiamondFill size={15} />
            </p>
          </Tooltip>
          <Text fontSize="13px" textAlign="left "fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory)
         </Text>
        </Flex>

      
        <Flex mt="15px" gap="30px">
        <Box w="103px" h="103px" borderRadius="10px" display="flex" alignItems="center" justifyContent="center" border="1px" bg={!Logo ? "linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)" : "none"}>
        {logoLoading ? (
      <Spinner size="lg" color="blue.500" /> // Spinner with blue color
    ) : Logo ? (
      <Image boxSize="103px" src={Logo} w="100%" />
    ) : (
      // Display a random icon if there is no logo
      <QuestionOutlineIcon w="50%" h="50%" color={colorMode === 'light' ? 'gray.400' : 'gray.200'} />
    )}
          </Box>
          <Box textAlign="center">
            <Box>
              <FormControl isRequired>
                <FormLabel textAlign={"center"} htmlFor="file">
                  <Text
                    bg="#1D9BF0"
                    py="5px"
                    px="10px"
                    borderRadius="3px"
                    color="white"
                  >
                   
                    Upload
                  </Text>
                </FormLabel>
              </FormControl>

              <Input
                type="file"
                id="file"
                display={"none"}
                onChange={addImage}
              />
            </Box>
        
          </Box>
        </Flex>

        <Text mt="15px" fontSize="12px" lineHeight="20px" fontWeight="400">
          Recommended size:256x256| JPG, PNG, GIF. Max size: 500kb
        </Text>
        <Divider mt="30px" mb="30px"  />
        <Flex alignItems="center" gap="8px">
        {/* Cover Image Label and Tooltip */}
        <Text>Cover image</Text>
          <Tooltip padding="10px"
           label="For your listing, incorporate a cover image that best represents your tool. This could be a high-quality screenshot from the tool's website or another relevant image. Ensure the image is at least 1600 pixels wide and 900 pixels tall for optimal display. The chosen cover image will be prominently featured on the page of your tool, accessible to all our visitors. The maximum file size allowed is 2 MB."
           aria-label=""
            >
         <BsQuestionDiamondFill size={15} />
      </Tooltip>
      <Text fontSize="13px" textAlign="left "fontWeight="bold" color={mandatoryTextColor}>
         (Mandatory)
         </Text>
      </Flex>

       <Text mt="15px" fontSize="12px" lineHeight="20px" fontWeight="400">
       This image will be used as preview of your tool
      </Text>

        <Stack
         backgroundSize="cover"
         borderRadius="10px"
         display="flex"
         alignItems="center"
        justifyContent="center"
        border="1px"
        width={["100%", "70%", "85%", "550px"]}
         height={["auto", "auto", "310px"]}
        sx={{
       "@media screen and (max-width: 600px)": {
      width: "100%",
      height: "200px",
    }
  }}
  style={{
    backgroundImage: coverImageLoading ? 'none' : `url(${Cover_image})`
  }}
>
  {coverImageLoading ? (
    <Spinner size="lg" color="blue.500" />
  ) : (
    <Box textAlign="center">
      <FormLabel textAlign="center" htmlFor="cfile">
        <Text
          w="fit-content"
          m="auto"
          bg="#1D9BF0"
          py="5px"
          px="10px"
          borderRadius="3px"
          color="white"
        >
          Upload
        </Text>
      </FormLabel>
      <Input
        type="file"
        id="cfile"
        display="none"
        onChange={(e) => addcoverimg(e)}
      />
    </Box>
  )}
</Stack>

        <Divider mt="30px" mb="30px"  />
       
       
        <Flex alignItems="center" gap="8px">
        Gallery
          <Tooltip padding="10px"
            label="Enhance your tool's listing by adding up to three images in the gallery section, showcasing its features and functionalities. Feel free to include screenshots of your tool in use, diagrams, or charts that illustrate its effectiveness, or any visual representation of its prime advantages. The images should be of high quality, with dimensions not less than 1600 pixels in width and 900 pixels in height. They will be displayed to all visitors on your tool’s page. The maximum file size for each image is 2 MB."
            aria-label=""
          >
            <p>
              <BsQuestionDiamondFill size={15} />
            </p>
          </Tooltip>
        </Flex>

        <Text fontSize="14px" lineHeight="32px" fontWeight="400">
          Upload up to 3 images showcasing your tool's features and benefits.
        </Text>

        <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 3 }} gap="10px">
  {Array.from({ length: 3 }).map((_, index) => (
    <Box key={index} textAlign="center">
    <Stack
    key={index}
    backgroundImage={Galary_image[index] ? `url(${Galary_image[index]})` : undefined}
    backgroundSize="cover" // Ensure the image covers the slot fully
    backgroundRepeat="no-repeat" // Prevent the image from repeating
    backgroundPosition="center" // Center the image within the slot
    mt="10px"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    width="154px"
    borderRadius="10px"
    height="110px"
    border="1px solid" // Adjusted to show the border clearly
    borderColor="gray.200" // Example border color, adjust as needed
  >
      <FormLabel textAlign={"center"} htmlFor={`gfile-${index}`}>
        <Text py="5px" px="10px" borderRadius="3px" color="white" bg="#1D9BF0">
          {Galary_image[index] ? "Replace" : "+Upload"}
        </Text>
      </FormLabel>
      <Input
        type="file"
        id={`gfile-${index}`}
        display="none"
        onChange={(e) => addGalary_image(e, index)} // Adjust this function to handle both adding and replacing images
      />
      </Stack>
 {Galary_image[index] && (
      <Button
        size="sm"
        colorScheme="red"
        mt="2" // Margin top for spacing between the stack and the button
        onClick={() => removeGalary_image(index)}
      >
        Remove
      </Button>
    )}
  </Box>
))}
    

</SimpleGrid>

        <Divider mt="30px" mb="30px"  />

        <Text fontSize="18px" lineHeight="32px" fontWeight="400">
          YouTube video
        </Text>

        <Text fontSize="14px" lineHeight="32px" fontWeight="400">
          Provide us with your YouTube video link, and we will embed it on your
          tool's page
        </Text>
        <FormControl>
          <FormLabel fontSize="14px" lineHeight="24px" fontWeight="400">
            Link to the video
          </FormLabel>
          <Input
            name="Youtube_embed"
            value={Youtube_embed}
            onChange={handlechange}
            borderColor="gray.400" // Sets a default border color.
            _hover={{ borderColor: "gray.500" }} // Changes border color on hover.
            _focus={{ 
              borderColor: "blue.500", // Changes border color on focus.
              boxShadow: "0 0 0 1px #3182ce" // Optional: Adds a shadow that matches the focus border color.
            }}
          />
        </FormControl>
      </Box>
    </>
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Box,
  Text,
  Heading,
  Image,
  SimpleGrid,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Stack,
  useColorModeValue,
  Flex
} from '@chakra-ui/react';
import notification from "../Toast";
import debounce from 'lodash.debounce';
import { useMediaQuery } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { color } from 'framer-motion';

const BlogLandingPage_home = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState("");
  //const inputbox=useColorModeValue("#ffffff", "#1c1c1c");
  const inputbox=useColorModeValue("#ffffff", "#ffffff");
  const [subscribing, setSubscribing] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  
  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");
  const bg = useColorModeValue("#ffffff", "#222222");

  useEffect(() => {
    setLoading(true);
    const fetchBlogsFromBackend = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/ghost`, {
          params: { page }
        });
        
        if (!response.data || !response.data.posts) {
          throw new Error("No posts found in response");
        }

        const publishedBlogs = response.data.posts.map(post => ({
          _id: post.id,
          title: post.title,
          slug: post.slug,
          coverImage: post.feature_image,
          summary: post.excerpt,
          author: post.primary_author?.name || "Unknown Author",
          published_at: post.published_at
        }));

        setBlogs(prevBlogs => [...prevBlogs, ...publishedBlogs]);
        setFilteredBlogs(prevBlogs => [...prevBlogs, ...publishedBlogs]);
      } catch (error) {
        console.error("Error fetching blogs from backend:", error.response || error.message);
        notification("error", `Failed to fetch blogs: ${error.response?.data?.errors?.[0]?.message || error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogsFromBackend();
  }, [page]);

  const searchBlogs = (searchValue) => {
    const lowercasedValue = searchValue.toLowerCase();
    const filteredData = blogs.filter(blog =>
      blog.title?.toLowerCase().includes(lowercasedValue) ||
      blog.summary?.toLowerCase().includes(lowercasedValue)
    );
    setFilteredBlogs(filteredData);
  };

  const debouncedSearch = useCallback(debounce((value) => searchBlogs(value), 300), [blogs]);

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleSubscribe = async () => {
    if (!email) return notification("error", "Please enter an email");
    setSubscribing(true);
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });
      notification("success", "Subscribed successfully!");
      setEmail("");
    } catch (error) {
      notification("error", "Failed to subscribe. Please try again.");
    } finally {
      setSubscribing(false);
    }
  };

  return (
    <Box mt={7} px={0}>
     <Flex direction={isMobile ? "column" : "row"} justifyContent="space-between" alignItems={isMobile ? "flex-start" : "center"} mb={6}>
     <Heading as="h2" fontSize="2xl" color='#1D9BF0' pb={{ base: 3 , md: 0}}>
      <Link to="https://newsletter.aizones.io/">Newsletter ➡️</Link>

</Heading>
<Flex 
  direction={isMobile ? "column" : "row"} 
  width={isMobile ? "100%" : "auto"} 
  gap={3}
  p={4}
  bgGradient="linear(to-r, blue.100, pink.100)"
  _dark={{
    bgGradient: "linear(to-r, rgba(26, 140, 216, 0.8), rgba(236, 64, 122, 0.8))"
  }}
  
  borderRadius="lg"
  boxShadow="sm"
  border="1px solid"
  borderColor={useColorModeValue('blue.100', 'blue.700')}
  _hover={{
    boxShadow: 'md',
    borderColor: '#1D9BF0'
  }}
  transition="all 0.3s ease"
>
  <Input
    placeholder="Enter your email for AI updates ✨"
    value={email}
    bg={inputbox}
    onChange={(e) => setEmail(e.target.value)}
    width={isMobile ? "100%" : "300px"}
    size="lg"
    borderWidth="2px"
    borderColor={useColorModeValue('blue.200', 'blue.600')}
    _hover={{
      borderColor: '#1D9BF0'
    }}
    _focus={{
      borderColor: '#1D9BF0',
      boxShadow: '0 0 0 1px #1D9BF0'
    }}
    _placeholder={{ 
      color: '#718096',
      opacity: 0.8
    }}
  />
  <Button 
    isLoading={subscribing} 
    color="#ffffff" 
    onClick={handleSubscribe}  
    bg="#1D9BF0" 
    width={isMobile ? "100%" : "auto"}
    size="lg"
    _hover={{
      bg: 'blue.500',
      transform: 'translateY(-2px)'
    }}
    _active={{
      bg: 'blue.600'
    }}
    fontWeight="bold"
    px={8}
  >
    Subscribe 🚀
  </Button>
</Flex>
      </Flex>
      <Text as="h3" fontSize="lg" color={font} mb={6} maxW="800px">
        Stay updated with the latest AI trends, tools, and insights.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        {filteredBlogs.map(blog => (
          <Box
            bg={bg}
            key={blog._id}
            borderRadius="lg"
            overflow="hidden"
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            _hover={{ transform: "translateY(-5px)", boxShadow: "xl" }}
          >
            <a href={`http://newsletter.aizones.io/${blog.slug}`} rel="noopener noreferrer">
              <Image src={`${blog.coverImage}?height=300`} height="200px" width="100%" alt={blog.title} objectFit="cover" />
              <Box p={5}>
                <Heading color={heading} as="h3" fontSize="lg" fontWeight="bold" mb={2}>{blog.title}</Heading>
                <Text fontSize="sm" color={font} noOfLines={3}>{blog.summary}</Text>
                <Text fontSize="sm" color={font} mt={3} fontWeight="medium">
                By {blog.author} - {new Date(blog.published_at).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ')}
                </Text>
              </Box>
            </a>
          </Box>
        ))}
      </SimpleGrid>
      <Flex justifyContent="center" mt={6}>
        <Button isLoading={loading} onClick={() => setPage(prevPage => prevPage + 1)}>
          Load More
        </Button>
      </Flex>
    </Box>
  );
};

export default BlogLandingPage_home;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Button,
//   AlertDialog,
//   AlertDialogOverlay,
//   AlertDialogContent,
//   AlertDialogHeader,
//   AlertDialogCloseButton,
//   AlertDialogBody,
//   AlertDialogFooter,
//   useDisclosure,
//   Text,
// } from "@chakra-ui/react";

// function ThankYouModal({ isOpen }) {
//   const { onClose } = useDisclosure();
//   const navigate = useNavigate();
//   const cancelRef = React.useRef();

//   const handleClose = () => {
//     onClose();
//     navigate('/');
//   };

//   return (
//     <AlertDialog
//       motionPreset="slideInBottom"
//       leastDestructiveRef={cancelRef}
//       onClose={onClose}
//       isOpen={isOpen}
//       isCentered
//     >
//       <AlertDialogOverlay />
//       <AlertDialogContent>
//         <AlertDialogHeader fontSize="lg" fontWeight="bold">Thank You!</AlertDialogHeader>
//         <AlertDialogCloseButton />
//         <AlertDialogBody textAlign="left">
//           <Text mb={4}>
//             Your tool submission is <Text as="span" fontWeight="bold">complete</Text>! We will review your submission within the next 1-7 days. Once approved, your tool will go live, and we will notify you accordingly.
//           </Text>
//           <Text>
//             Should you require further assistance or need to provide additional details, please don't hesitate to reach out to us at <Text as="span" fontWeight="bold">hello@aizones.io</Text>. Thank you for choosing <Text as="span" fontWeight="bold">AI ZONES</Text>!
//           </Text>
//         </AlertDialogBody>
//         <AlertDialogFooter>
//           <Button ref={cancelRef} onClick={handleClose}>
//             Close
//           </Button>
//         </AlertDialogFooter>
//       </AlertDialogContent>
//     </AlertDialog>
//   );
// }

// export default ThankYouModal;




// import React from "react";
// import { useNavigate, Link } from "react-router-dom";
// import {
//   Button,
//   AlertDialog,
//   AlertDialogOverlay,
//   AlertDialogContent,
//   AlertDialogHeader,
//   AlertDialogCloseButton,
//   AlertDialogBody,
//   AlertDialogFooter,
//   useDisclosure,
//   Text,
//   Box,
// } from "@chakra-ui/react";

// function ThankYouModal({ isOpen, slug }) {
//   const { onClose } = useDisclosure();
//   const navigate = useNavigate();
//   const cancelRef = React.useRef();

//   const handleClose = () => {
//     onClose();
//     navigate('/');
//   };

//   return (
//     <AlertDialog
//       motionPreset="slideInBottom"
//       leastDestructiveRef={cancelRef}
//       onClose={onClose}
//       isOpen={isOpen}
//       isCentered
//     >
//       <AlertDialogOverlay />
//       <AlertDialogContent>
//         <AlertDialogHeader fontSize="lg" fontWeight="bold">Thank You!</AlertDialogHeader>
//         <AlertDialogCloseButton />
//         <AlertDialogBody textAlign="left">
//           <Text mb={4}>
//             Your tool submission is <Text as="span" fontWeight="bold">complete</Text>! We will review your submission within the next 1-7 days. Once approved, your tool will go live, and we will notify you accordingly.
//           </Text>
//           <Text mb={4}>
//             You are now part of the <Text as="span" fontWeight="bold">Largest AI Tool Directory in the World</Text>!
//           </Text>
//           <Box textAlign="center">
//             <Link to={`/tool/${slug}`}>
//               <Button colorScheme="teal">
//                 Visit Your Product Page
//               </Button>
//             </Link>
//           </Box>
//           <Text mt={4}>
//             Should you require further assistance or need to provide additional details, please don't hesitate to reach out to us at <Text as="span" fontWeight="bold">hello@aizones.io</Text>. Thank you for choosing <Text as="span" fontWeight="bold">AI ZONES</Text>!
//           </Text>
//         </AlertDialogBody>
//         <AlertDialogFooter>
//           <Button ref={cancelRef} onClick={handleClose}>
//             Close
//           </Button>
//         </AlertDialogFooter>
//       </AlertDialogContent>
//     </AlertDialog>
//   );
// }




// import React from "react";
// import { useNavigate, Link } from "react-router-dom";
// import {
//   Button,
//   AlertDialog,
//   AlertDialogOverlay,
//   AlertDialogContent,
//   AlertDialogHeader,
//   AlertDialogCloseButton,
//   AlertDialogBody,
//   AlertDialogFooter,
//   useDisclosure,
//   Text,
//   Box,
//   Image,
//   Divider,
//   Flex,
//   Switch,
//   VStack,
// } from "@chakra-ui/react";
// import { motion } from "framer-motion";
// import { FiCopy } from "react-icons/fi";
// import { MdOutlineDoneAll } from "react-icons/md";

// const floatAnimation = `
//   @keyframes float {
//     0% {
//       transform: translateY(0);
//     }
//     50% {
//       transform: translateY(-10px);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }
// `;

// function ThankYouModal({ isOpen, slug, logo }) {
//   const { onClose } = useDisclosure();
//   const navigate = useNavigate();
//   const cancelRef = React.useRef();
//   const [copy, setCopy] = React.useState(false);
//   const [light, setLight] = React.useState(false);

//   const handleClose = () => {
//     onClose();
//     navigate('/');
//   };

//   const handleCopy = () => {
//     const imageUrlToCopy = `<a href="${process.env.REACT_APP_PUBLIC_URL}/tool/${slug}"> <img src="${light ? 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20light.png' : 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20DARK.png'}"/></a>`;

//     navigator.clipboard
//       .writeText(imageUrlToCopy)
//       .then(() => {
//         setCopy(true);
//       })
//       .catch((error) => {
//         console.error("Failed to copy: ", error);
//       });
//   };

//   return (
//     <>
//       <style>
//         {floatAnimation}
//       </style>
//       <AlertDialog
//         motionPreset="slideInBottom"
//         leastDestructiveRef={cancelRef}
//         onClose={onClose}
//         isOpen={isOpen}
//         isCentered
//         size="xl"
//       >
//         <AlertDialogOverlay />
//         <AlertDialogContent>
//           <AlertDialogHeader fontSize="2xl" fontWeight="bold">Thank You!</AlertDialogHeader>
//           <AlertDialogCloseButton />
//           <Box maxHeight="70vh" overflowY="auto">
//             <AlertDialogBody textAlign="center">
//               <motion.div
//                 initial={{ scale: 0 }}
//                 animate={{ rotate: 360, scale: 1 }}
//                 transition={{
//                   type: "spring",
//                   stiffness: 260,
//                   damping: 20,
//                 }}
//                 style={{
//                   borderRadius: "50%",
//                   padding: "10px",
//                   display: "inline-block",
//                   animation: "float 3s ease-in-out infinite",
//                 }}
//               >
//                 <Image
//                   src={logo} // Display the uploaded logo
//                   alt="Congratulations"
//                   borderRadius="full"
//                   boxSize="150px"
//                   mb={4}
//                   mx="auto"
//                 />
//               </motion.div>
//               <Text fontSize="xl" fontWeight="bold" mb={4}>
//                 Congratulations!
//               </Text>
//               <Text mb={4}>
//                 Your product is now part of <Text as="span" fontWeight="bold">AI Zones</Text>!
//               </Text>
//               <Box textAlign="center" mb={4}>
//                 <Link to={`/tool/${slug}`}>
//                   <Button colorScheme="teal" size="lg">
//                     Visit Your Product Page
//                   </Button>
//                 </Link>
//               </Box>
//               <Divider mb={4} />
//               <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign="left">
//                 Additional Steps
//               </Text>
//               <VStack spacing={4} align="stretch">
//                 <Box textAlign="left">
//                   <Text mb={2} fontWeight="bold">
//                     Embed your product page on your website for better credibility and SEO.
//                   </Text>
//                   <Flex w="100%" flexDirection="column" gap={1} alignItems="center" mb={4}>
//                     <Flex w="100%" alignItems="center" justifyContent="space-between">
//                       <Box pb={5}>
//                         <Image h="53px" src={light ? '/path-to-light-logo.png' : '/path-to-dark-logo.png'} />
//                       </Box>
//                       <Flex gap="20px" alignItems="center">
//                         <Text>Dark</Text>
//                         <Switch onChange={(e) => setLight(e.target.checked)} />
//                         <Text>Light</Text>
//                       </Flex>
//                     </Flex>
//                     <Box padding={5}>
//                       {!copy ? (
//                         <Button p={0} display="flex" gap={2} bg="none" _hover={{ bg: "" }} onClick={handleCopy}>
//                           <FiCopy size={30} />
//                           <Text>Copy embed code</Text>
//                         </Button>
//                       ) : (
//                         <Button borderRadius="2px" _hover={{ bg: "" }}>
//                           <MdOutlineDoneAll size={20} style={{ color: "#1D9BF0" }} />
//                         </Button>
//                       )}
//                     </Box>
//                   </Flex>
//                 </Box>
//                 <Divider mb={4} />
//                 <Box textAlign="left">
//                   <Text mb={2} fontWeight="bold">
//                     Get access to the creator's portal to add or edit your product details.
//                   </Text>
//                   <Box textAlign="center" mb={4}>
//                     <Image src="https://ai-zones.b-cdn.net/Website-utilities%20/Creators%20portal%20-Request%20access.gif" alt="Creator's Portal" borderRadius="md" mb={4} />
//                   </Box>
//                   <Text fontSize="sm" textAlign="left">
//                     <Text as="span" fontWeight="bold">Steps:</Text>
//                     <ol style={{ paddingLeft: "20px" }}>
//                       <li>Go to your product page.</li>
//                       <li>In the navigation bar, find the button called "Request Access".</li>
//                       <li>Click on that button and you will be prompted to add your official domain email ID.</li>
//                       <li>Verify your product and get access to edit your product details.</li>
//                     </ol>
//                   </Text>
//                 </Box>
//               </VStack>
//             </AlertDialogBody>
//           </Box>
//           <AlertDialogFooter>
//             <Button ref={cancelRef} onClick={handleClose}>
//               Close
//             </Button>
//           </AlertDialogFooter>
//         </AlertDialogContent>
//       </AlertDialog>
//     </>
//   );
// }

// export default ThankYouModal;



















import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Text,
  Box,
  Image,
  Divider,
  Flex,
  Switch,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FiCopy } from "react-icons/fi";
import { MdOutlineDoneAll } from "react-icons/md";

const floatAnimation = `
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

function ThankYouModal({ isOpen, slug, logo }) {
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const cancelRef = React.useRef();
  const [copy, setCopy] = React.useState(false);
  const [light, setLight] = React.useState(false);

  const handleClose = () => {
    onClose();
    navigate('/');
  };

  const handleCopy = () => {
    const imageUrlToCopy = `<a href="${process.env.REACT_APP_PUBLIC_URL}/tool/${slug}"> <img src="${light ? 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20light.png' : 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20DARK.png'}"/></a>`;
    navigator.clipboard
      .writeText(imageUrlToCopy)
      .then(() => {
        setCopy(true);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  console.log("Light mode:", light);
  console.log("Image URL:", light ? 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20light.png' : 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20DARK.png');

  return (
    <>
      <style>
        {floatAnimation}
      </style>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="xl"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="2xl" fontWeight="bold"></AlertDialogHeader>
          <AlertDialogCloseButton onClick={handleClose} />
          <Box maxHeight="70vh" overflowY="auto">
            <AlertDialogBody textAlign="center">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
                style={{
                  borderRadius: "50%",
                  padding: "10px",
                  display: "inline-block",
                  animation: "float 3s ease-in-out infinite",
                }}
              >
                <Image
                  src={logo} // Display the uploaded logo
                  alt="Congratulations"
                  borderRadius="full"
                  boxSize="150px"
                  mb={4}
                  mx="auto"
                />
              </motion.div>
              <Text fontSize="xl" fontWeight="bold" mb={4}>
                Congratulations!
              </Text>
              <Text mb={4}>
                Your product is now part of <Text as="span" fontWeight="bold">AI Zones</Text>!
              </Text>
              <Box textAlign="center" mb={4}>
                <Link to={`/tool/${slug}`}>
                  <Button colorScheme="teal" size="lg">
                    Visit Your Product Page
                  </Button>
                </Link>
              </Box>
              <Box textAlign="center" mb={4}>
              <a href="https://www.passionfroot.me/ai-zones" target="_blank" >
                  <Button variant={"outline"}  size="lg">
                    Advertise ➡️
                  </Button>
                  </a>
              </Box>
              <Divider mb={4} />
              <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign="left">
                Additional Steps
              </Text>
              <VStack spacing={4} align="stretch">
                <Box textAlign="left">
                  <Text mb={2} fontWeight="bold">
                    Embed your product page on your website for better credibility and SEO.
                  </Text>
                  <Flex w="100%" flexDirection="column" gap={1} alignItems="center" mb={4}>
                    <Flex w="100%" alignItems="center" justifyContent="space-between">
                      <Box pb={5} pt={5}>
                        <Image h="53px" src={light ? 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20light.png' : 'https://ai-zones.b-cdn.net/Website-utilities%20/Embed%20DARK.png'} />
                      </Box>
                      <Flex gap="20px" alignItems="center">
                        <Text>Dark</Text>
                        <Switch onChange={(e) => setLight(e.target.checked)} />
                        <Text>Light</Text>
                      </Flex>
                    </Flex>
                    <Box padding={5}>
                      {!copy ? (
                        <Button p={0} display="flex" gap={2} bg="none" _hover={{ bg: "" }} onClick={handleCopy}>
                          <FiCopy size={30} />
                          <Text>Copy embed code</Text>
                        </Button>
                      ) : (
                        <Button borderRadius="2px" _hover={{ bg: "" }}>
                          <MdOutlineDoneAll size={20} style={{ color: "#1D9BF0" }} />
                        </Button>
                      )}
                    </Box>
                  </Flex>
                </Box>
                <Divider mb={4} />
                <Box textAlign="left">
                  <Text mb={2} fontWeight="bold">
                    2. Get access to the creator's portal to add or edit your product details.
                  </Text>
                  <Box textAlign="center" mb={4}>
                    <Image src="https://ai-zones.b-cdn.net/Website-utilities%20/Creators%20portal%20-Request%20access.gif" alt="Creator's Portal" borderRadius="md" mb={4} />
                  </Box>
                  <Text fontSize="sm" textAlign="left">
                    <Text as="span" fontWeight="bold">Steps:</Text>
                    <ol style={{ paddingLeft: "20px" }}>
                      <li>Go to your product page.</li>
                      <li>In the navigation bar, find the button called "Request Access".</li>
                      <li>Click on that button and you will be prompted to add your official domain email ID.</li>
                      <li>Verify your product and get access to edit your product details.</li>
                    </ol>
                  </Text>
                </Box>
              </VStack>
            </AlertDialogBody>
          </Box>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default ThankYouModal;
import { Box } from "@mui/material";
import React from "react";
import { Stack, Flex, Text, Button, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import { ListModal_home } from "../ListModal_home";
import LoadingAnimation from '../LoadingAnimation';
import style from "../../Style/List.module.css";

export const LIstView_home = ({
  data,
  showLoader,
  setcat,
  setPageName,
  setFilterLoader,
  handleUserInteraction,
}) => {
  const bg = useColorModeValue("gray.800", "#eeeeee");
  

  // Helper function to define the number of tools before an ad
  const TOOLS_BEFORE_AD = useBreakpointValue({ base: 8, lg: 9 }); // Change this value to set the number of tools before the ad

  return (
    <>
      <Stack w="100%">
        <Box className={style.listbox} w="100%">
          {data?.filter((e) => e.verify).map((el, i) => (
            <React.Fragment key={i}>
              {/* Render Tool */}
              <Box>
                <ListModal_home
                  el={el}
                  i={i}
                  setcat={setcat}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  handleUserInteraction={handleUserInteraction}
                />
              </Box>

           
            </React.Fragment>
          ))}
        </Box>
      </Stack>

      {showLoader && (
        <Box
          height="100px"
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingAnimation size="xl" />
        </Box>
      )}
    </>
  );
};














// import { Box } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Stack, Flex, Text, Button, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
// import { ListModal_home } from "../ListModal_home";
// import LoadingAnimation from '../LoadingAnimation';
// import style from "../../Style/List.module.css";
// import axios from "axios"; // Ensure Axios is installed

// export const LIstView_home = ({
//   data,
//   showLoader,
//   setcat,
//   setPageName,
//   setFilterLoader,
//   handleUserInteraction,
// }) => {
//   const [sponsoredTools, setSponsoredTools] = useState([]); // State to store fetched sponsored tools
//   const bg = useColorModeValue("gray.800", "#eeeeee");

//   // Helper function to define the number of tools before an ad
//   const TOOLS_BEFORE_AD = useBreakpointValue({ base: 8, lg: 9 }); // Change this value to set the number of tools before the ad

//   // Fetch Sponsored Tools data from backend
//   useEffect(() => {
//     const fetchSponsoredTools = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
//         setSponsoredTools(response.data); // Store data in state
//       } catch (error) {
//         console.error("Error fetching sponsored tools:", error);
//       }
//     };

//     fetchSponsoredTools();
//   }, []);

//   // Function to handle click on sponsored tool URL
//   const handleSponsoredToolClick = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
//     } catch (error) {
//       console.error("Error incrementing click count:", error);
//     }
//   };

//   return (
//     <>
//       <Stack w="100%">
//         <Box className={style.listbox} w="100%">
//           {data?.filter((e) => e.verify).map((el, i) => (
//             <React.Fragment key={i}>
//               {/* Render Tool */}
//               <Box>
//                 <ListModal_home
//                   el={el}
//                   i={i}
//                   setcat={setcat}
//                   setPageName={setPageName}
//                   setFilterLoader={setFilterLoader}
//                   handleUserInteraction={handleUserInteraction}
//                 />
//               </Box>

//               {/* Render Ad Banner after every 'TOOLS_BEFORE_AD' tools */}
//               {(i + 1) % TOOLS_BEFORE_AD === 0 && sponsoredTools.length > 0 && (
//                 <Box
//                   border="1px solid"
//                   borderColor="gray.200"
//                   borderRadius="8px"
//                   padding="16px"
//                   background="white"
//                   boxShadow="md"
//                   bg={bg}
//                   marginBottom="20px"
//                   className={style.adbanner}
//                   _hover={{ boxShadow: "lg" }}
//                   width="100%" // Ensure the ad spans the full width
//                 >
//                   <Flex alignItems="center" gap={4}>
//                     {/* Ad Logo */}
//                     <img
//                       src={sponsoredTools[i % sponsoredTools.length].Logo || "https://ai-zones.b-cdn.net/images/MsRdDb3GuOo3.svg?height=80"} // Replace with default ad logo if none exists
//                       alt="Ad logo"
//                       boxSize="60px"
//                       height="80px"
//                       width="80px"
//                       borderRadius="full"
//                     />

//                     {/* Title and Tagline */}
//                     <Flex flexDirection="column" flex="1">
//                       <Text fontSize="lg" fontWeight="bold">
//                         {sponsoredTools[i % sponsoredTools.length].Title || "Sponsored Tool Title"} {/* Replace with dynamic ad title */}
//                       </Text>
//                       <Text fontSize="sm" color="gray.500">
//                         {sponsoredTools[i % sponsoredTools.length].Tagline || "This is the tagline for the sponsored tool."} {/* Replace with dynamic ad tagline */}
//                       </Text>
//                     </Flex>

//                     {/* URL Button */}
//                     <Button
//                       as="a"
//                       href={sponsoredTools[i % sponsoredTools.length].URL || "#"} // Replace with dynamic ad URL
//                       target="_blank"
//                       
//                       colorScheme="blue"
//                       size="sm"
//                       onClick={() => handleSponsoredToolClick(sponsoredTools[i % sponsoredTools.length]._id)} // Increment click count
//                     >
//                       Visit
//                     </Button>
//                   </Flex>
//                 </Box>
//               )}
//             </React.Fragment>
//           ))}
//         </Box>
//       </Stack>

//       {showLoader && (
//         <Box
//           height="100px"
//           w="100%"
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <LoadingAnimation size="xl" />
//         </Box>
//       )}
//     </>
//   );
// };







// import { Box } from "@mui/material";
// import React, { useEffect, useState, useRef } from "react";
// import { Stack, Flex, Text, Button, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
// import { ListModal_home } from "../ListModal_home";
// import LoadingAnimation from "../LoadingAnimation";
// import style from "../../Style/List.module.css";
// import axios from "axios";

// export const LIstView_home = ({
//   data,
//   showLoader,
//   setcat,
//   setPageName,
//   setFilterLoader,
//   handleUserInteraction,
// }) => {
//   const [sponsoredTools, setSponsoredTools] = useState([]); // Store all active sponsored tools
//   const currentAdIndex = useRef(0); // Ref to track the current ad index
//   const bg = useColorModeValue("gray.800", "#eeeeee");

//   // Define the number of tools before an ad appears
//   const TOOLS_BEFORE_AD = useBreakpointValue({ base: 8, lg: 9 });

//   // Fetch Sponsored Tools data from backend
//   useEffect(() => {
//     const fetchSponsoredTools = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
//         setSponsoredTools(response.data); // Store data in state
//       } catch (error) {
//         console.error("Error fetching sponsored tools:", error);
//       }
//     };

//     fetchSponsoredTools();
//   }, []);

//   // Get the next ad to display
//   const getNextAd = () => {
//     if (sponsoredTools.length === 0) return null; // No ads available
//     const ad = sponsoredTools[currentAdIndex.current];
//     currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length; // Move to the next ad or restart
//     return ad;
//   };

//   // Function to handle click on sponsored tool URL
//   const handleSponsoredToolClick = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
//     } catch (error) {
//       console.error("Error incrementing click count:", error);
//     }
//   };

//   return (
//     <>
//       <Stack w="100%">
//         <Box className={style.listbox} w="100%">
//           {data?.filter((e) => e.verify).map((el, i) => (
//             <React.Fragment key={i}>
//               {/* Render Tool */}
//               <Box>
//                 <ListModal_home
//                   el={el}
//                   i={i}
//                   setcat={setcat}
//                   setPageName={setPageName}
//                   setFilterLoader={setFilterLoader}
//                   handleUserInteraction={handleUserInteraction}
//                 />
//               </Box>

//               {/* Render Ad Banner after every 'TOOLS_BEFORE_AD' tools */}
//               {(i + 1) % TOOLS_BEFORE_AD === 0 && sponsoredTools.length > 0 && (
//                 (() => {
//                   const adTool = getNextAd(); // Get the next ad to display
//                   return (
//                     adTool && (
//                       <Box
//                         border="1px solid"
//                         borderColor="gray.200"
//                         borderRadius="8px"
//                         padding="16px"
//                         background="white"
//                         boxShadow="md"
//                         bg={bg}
//                         marginBottom="20px"
//                         className={style.adbanner}
//                         _hover={{ boxShadow: "lg" }}
//                         width="100%"
//                       >
//                         <Flex alignItems="center" gap={4}>
//                           {/* Ad Logo */}
//                           <img
//                             src={adTool.Logo || "https://ai-zones.b-cdn.net/images/MsRdDb3GuOo3.svg?height=80"}
//                             alt="Ad logo"
//                             style={{ height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover" }}
//                           />

//                           {/* Title and Tagline */}
//                           <Flex flexDirection="column" flex="1">
//                             <Text fontSize="lg" fontWeight="bold">
//                               {adTool.Title || "Sponsored Tool"}
//                             </Text>
//                             <Text fontSize="sm" color="gray.500">
//                               {adTool.Tagline || "Discover premium AI tools and services"}
//                             </Text>
//                           </Flex>

//                           {/* URL Button */}
//                           <Button
//                             as="a"
//                             href={adTool.URL || "#"}
//                             target="_blank"
//                             
//                             colorScheme="blue"
//                             size="sm"
//                             onClick={() => handleSponsoredToolClick(adTool._id)}
//                           >
//                             Visit
//                           </Button>
//                         </Flex>
//                       </Box>
//                     )
//                   );
//                 })()
//               )}
//             </React.Fragment>
//           ))}
//         </Box>
//       </Stack>

//       {showLoader && (
//         <Box
//           height="100px"
//           w="100%"
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <LoadingAnimation size="xl" />
//         </Box>
//       )}
//     </>
//   );
// };





// import { Box } from "@mui/material";
// import React, { useEffect, useState, useRef } from "react";
// import { Stack, Flex, Text, Button, useColorModeValue, useBreakpointValue, background, border } from "@chakra-ui/react";
// import { ListModal_home } from "../ListModal_home";
// import LoadingAnimation from "../LoadingAnimation";
// import style from "../../Style/List.module.css";
// import axios from "axios";
// import { blue } from "@mui/material/colors";

// export const LIstView_home = ({
//   data,
//   showLoader,
//   setcat,
//   setPageName,
//   setFilterLoader,
//   handleUserInteraction,
// }) => {
//   const [sponsoredTools, setSponsoredTools] = useState([]); // Store all active sponsored tools
//   const currentAdIndex = useRef(0); // Ref to track the current ad index
//   const bg = useColorModeValue("#ffffff", "#222222");
//   const font = useColorModeValue("grey.700", "grey.400");
//   const heading = useColorModeValue("grey.800", "#eeeeee");
//   const border = useColorModeValue("blue.400", "#blue.400");
 

//   // Define the number of tools before an ad appears
//   const TOOLS_BEFORE_AD = useBreakpointValue({ base: 8, lg: 9 });

//   // Fetch Sponsored Tools data from backend
//   useEffect(() => {
//     const fetchSponsoredTools = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
//         setSponsoredTools(response.data); // Store data in state
//       } catch (error) {
//         console.error("Error fetching sponsored tools:", error);
//       }
//     };

//     fetchSponsoredTools();
//   }, []);

//   // Get the next ad to display
//   const getNextAd = () => {
//     if (sponsoredTools.length === 0) return null; // No ads available
//     const ad = sponsoredTools[currentAdIndex.current];
//     currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length; // Move to the next ad or restart
//     return ad;
//   };

//   // Function to handle click on sponsored tool URL
//   const handleSponsoredToolClick = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
//     } catch (error) {
//       console.error("Error incrementing click count:", error);
//     }
//   };

//   // Function to handle impression of sponsored tool
//   const handleSponsoredToolImpression = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
//     } catch (error) {
//       console.error("Error incrementing impressions count:", error);
//     }
//   };

//   return (
//     <>
//       <Stack w="100%">
//         <Box className={style.listbox} w="100%">
//           {data?.filter((e) => e.verify).map((el, i) => (
//             <React.Fragment key={i}>
//               {/* Render Tool */}
//               <Box>
//                 <ListModal_home
//                   el={el}
//                   i={i}
//                   setcat={setcat}
//                   setPageName={setPageName}
//                   setFilterLoader={setFilterLoader}
//                   handleUserInteraction={handleUserInteraction}
//                 />
//               </Box>

//               {/* Render Ad Banner after every 'TOOLS_BEFORE_AD' tools */}
//               {(i + 1) % TOOLS_BEFORE_AD === 0 && sponsoredTools.length > 0 && (
//                 (() => {
//                   const adTool = getNextAd(); // Get the next ad to display
//                   return (
//                     adTool && (
//                       // <Box
//                       //   border="1px solid"
//                       //   borderColor="gray.200"
//                       //   borderRadius="8px"
//                       //   padding="16px"
//                       //   // background="white"
//                       //   boxShadow="md"
//                       //   bg={bg}
//                       //   marginBottom="20px"
//                       //   // className={style.adbanner}
//                       //   style={{
//                       //     gridColumn: "1 / -1", // Makes the ad span all columns if inside a grid
//                       //     width: "100%", // Ensures full width
//                       //   }}
                        
//                       //   _hover={{ boxShadow: "lg" }}
//                       //   width="100%"
//                       //   onLoad={() => handleSponsoredToolImpression(adTool._id)} // Increment impressions count
//                       // >
//                       //   <Flex alignItems="center" gap={4}>
//                       //     {/* Ad Logo */}
//                       //     <img
//                       //       src={adTool.Logo || "https://ai-zones.b-cdn.net/Website-utilities%20/favicon-500px.png"}
//                       //       alt="Ad logo"
//                       //       style={{ height: "80px", width: "80px", borderRadius: "5px", objectFit: "cover" }}
//                       //     />

//                       //     {/* Title and Tagline */}
//                       //     <Flex flexDirection="column" flex="1">
//                       //       <Text fontSize="lg" fontWeight="bold">
//                       //         {adTool.Title}
//                       //       </Text>
//                       //       <Text fontSize="sm" color="gray.500">
//                       //         {adTool.Tagline}
//                       //       </Text>
//                       //     </Flex>

//                       //     {/* URL Button */}
//                       //     <Button
//                       //       as="a"
//                       //       href={adTool.URL || "#"}
//                       //       target="_blank"
//                       //       
//                       //       colorScheme="blue"
//                       //       size="sm"
//                       //       onClick={() => handleSponsoredToolClick(adTool._id)}
//                       //     >
//                       //       Visit
//                       //     </Button>
//                       //   </Flex>
//                       // </Box>
//  <Box
//   as="a"
//   href={adTool.finalURL} // Dynamic URL
//   target="_blank"
//   
//   position="relative" // For pseudo-element positioning
//   border="1px"
//   borderColor={font}
//   borderRadius="8px"
//   padding="16px"
//   bg={bg}
//   marginBottom="20px"
//   boxShadow="md"
//   style={{
//     gridColumn: "1 / -1", // Makes the ad span all columns if inside a grid
//     width: "100%", // Ensures full width
//     background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
//   }}
//   _hover={{ boxShadow: "lg" }}
//   _focus={{ boxShadow: "lg" }}
//   onClick={() => handleSponsoredToolClick(adTool._id)} // Increment click count
// >
//   {/* Gradient Highlight */}
//   <Box
//     position="absolute"
//     top="0"
//     left="0"
//     bottom="0"
//     width="4px"
//     bg="linear-gradient(to bottom, lightblue, pink)"
//     borderRadius="8px 0 0 8px"
//   />

//   {/* Sponsored Label */}
//   <Box
//     position="absolute"
//     top="8px"
//     right="8px"
//     background="rgba(0, 0, 0, 0.7)"
//     color={heading}
//     fontSize="12px"
//     fontWeight="bold"
//     borderRadius="4px"
//     padding="4px 6px"
//   >
//     Sponsored
//   </Box>

//   {/* Ad Content */}
//   <Flex alignItems="center" gap={4}>
//     {/* Ad Logo */}
//     <img
//       src={adTool.Logo}
//       alt={`${adTool.Title} logo`}
//       style={{
//         height: "80px",
//         width: "80px",
//         borderRadius: "5px",
//         objectFit: "cover",
//       }}
//     />

//     {/* Title and Tagline */}
//     <Flex flexDirection="column" color={heading} flex="1">
//       <Text fontSize="lg" fontWeight="bold">
//         {adTool.Title}
//       </Text>
//       <Text fontSize="sm" color={font}>
//         {adTool.Tagline}
//       </Text>
//     </Flex>
//   </Flex>
// </Box> 






//                     )
//                   );
//                 })()
//               )}
//             </React.Fragment>
//           ))}
//         </Box>
//       </Stack>
  

//       {showLoader && (
//         <Box
//           height="100px"
//           w="100%"
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <LoadingAnimation size="xl" />
//         </Box>
//       )}
//     </>
//   );
// };

















// import { Box } from "@mui/material";
// import React, { useEffect, useState, useRef } from "react";
// import { Stack, Flex, Text, Button, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
// import { ListModal_home } from "../ListModal_home";
// import LoadingAnimation from "../LoadingAnimation";
// import style from "../../Style/List.module.css";
// import axios from "axios";
// import { useLocation } from "react-router-dom"; // Assuming you are using React Router

// export const LIstView_home = ({
//   data,
//   showLoader,
//   setcat,
//   setPageName,
//   setFilterLoader,
//   handleUserInteraction,
// }) => {
//   const [sponsoredTools, setSponsoredTools] = useState([]); // Store all active sponsored tools
//   const currentAdIndex = useRef(0); // Ref to track the current ad index
//   const bg = useColorModeValue("#ffffff", "#222222");
//   const font = useColorModeValue("grey.700", "grey.400");
//   const heading = useColorModeValue("grey.800", "#eeeeee");
//   const location = useLocation(); // Get the current location

//   // Define the number of tools before an ad appears
//   const TOOLS_BEFORE_AD = useBreakpointValue({ base: 8, lg: 9 });

//   // Fetch Sponsored Tools data from backend
//   // useEffect(() => {
//   //   const fetchSponsoredTools = async () => {
//   //     try {
//   //       const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
//   //       setSponsoredTools(response.data); // Store data in state
//   //     } catch (error) {
//   //       console.error("Error fetching sponsored tools:", error);
//   //     }
//   //   };

//   //   fetchSponsoredTools();
//   // }, []);


//     // Fetch Sponsored Tools data from backend (skip if on home page)
//     useEffect(() => {
//       if (location.pathname !== "/") {
//         const fetchSponsoredTools = async () => {
//           try {
//             const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
//             setSponsoredTools(response.data); // Store data in state
//           } catch (error) {
//             console.error("Error fetching sponsored tools:", error);
//           }
//         };
  
//         fetchSponsoredTools();
//       }
//     }, [location.pathname]);

//   // Get the next ad to display
//   const getNextAd = () => {
//     if (sponsoredTools.length === 0) return null; // No ads available
//     const ad = sponsoredTools[currentAdIndex.current];
//     currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length; // Move to the next ad or restart
//     return ad;
//   };

//   // Function to handle click on sponsored tool URL
//   const handleSponsoredToolClick = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/click`);
//     } catch (error) {
//       console.error("Error incrementing click count:", error);
//     }
//   };

//   // Function to handle impression of sponsored tool
//   const handleSponsoredToolImpression = async (id) => {
//     try {
//       await axios.post(`${process.env.REACT_APP_API}/api/sponsoredtools/${id}/impression`);
//     } catch (error) {
//       console.error("Error incrementing impressions count:", error);
//     }
//   };

//   return (
//     <>
//       <Stack w="100%">
//         <Box className={style.listbox} w="100%">
//           {data?.filter((e) => e.verify).map((el, i) => (
//             <React.Fragment key={i}>
//               {/* Render Tool */}
//               <Box>
//                 <ListModal_home
//                   el={el}
//                   i={i}
//                   setcat={setcat}
//                   setPageName={setPageName}
//                   setFilterLoader={setFilterLoader}
//                   handleUserInteraction={handleUserInteraction}
//                 />
//               </Box>

//               {/* Render Ad Banner after every 'TOOLS_BEFORE_AD' tools, except on the home page */}
//               {(i + 1) % TOOLS_BEFORE_AD === 0 && sponsoredTools.length > 0 && location.pathname !== "/" && (
//                 (() => {
//                   const adTool = getNextAd(); // Get the next ad to display
//                   return (
//                     adTool && (
//                       <Box
//                       as="a"
//                       href={adTool.finalURL} // Dynamic URL
//                       target="_blank"
                      
//                       position="relative" // For pseudo-element positioning
//                       border="1px"
//                       borderColor={font}
//                       borderRadius="8px"
//                       padding="16px"
//                       bg={bg}
//                       marginBottom="20px"
//                       boxShadow="md"
//                       className={style.adbanner} // Add class for styling
//                       data-id={adTool._id} // Add data-id for Intersection Observer
//                       style={{
//                         width: "100%",
//                         background: `linear-gradient(to right, ${bg}, rgba(135, 206, 235, 0.2) 80%, rgba(255, 182, 193, 0.3))`,
//                       }}
//                       _hover={{ boxShadow: "lg" }}
//                       _focus={{ boxShadow: "lg" }}
//                       onClick={() => handleSponsoredToolClick(adTool._id)}
//                       onLoad={() => handleSponsoredToolImpression(adTool._id)}
//                     >
//                         {/* Gradient Highlight */}
//                         <Box
//                           position="absolute"
//                           top="0"
//                           left="0"
//                           bottom="0"
//                           width="4px"
//                           bg="linear-gradient(to bottom, lightblue, pink)"
//                           borderRadius="8px 0 0 8px"
//                         />

//                         {/* Sponsored Label */}
//                         <Box
//                           position="absolute"
//                           top="8px"
//                           right="8px"
//                           background="rgba(0, 0, 0, 0.7)"
//                           color={heading}
//                           fontSize="12px"
//                           fontWeight="bold"
//                           borderRadius="4px"
//                           padding="4px 6px"
//                         >
//                           Sponsored
//                         </Box>

//                         {/* Ad Content */}
//                         <Flex alignItems="center" gap={4} style={{ margin: "auto" }}>
//                           {/* Ad Logo */}
//                           <img
//                             src={`${adTool.Logo}?height=80`}
//                             alt={`${adTool.Title} logo`}
//                             style={{
//                               height: "80px",
//                               width: "80px",
//                               borderRadius: "5px",
//                               objectFit: "cover",
//                             }}
//                           />

//                           {/* Title and Tagline */}
//                           <Flex flexDirection="column" color={heading} flex="1">
//                             <Text fontSize="lg" fontWeight="bold">
//                               {adTool.Title}
//                             </Text>
//                             <Text fontSize="sm" color={font}>
//                               {adTool.Tagline}
//                             </Text>
//                           </Flex>
//                         </Flex>
//                       </Box>
//                     )
//                   );
//                 })()
//               )}
//             </React.Fragment>
//           ))}
//         </Box>
//       </Stack>

//       {showLoader && (
//         <Box
//           height="100px"
//           w="100%"
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <LoadingAnimation size="xl" />
//         </Box>
//       )}
//     </>
//   );
// };
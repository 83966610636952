import React, { useState, useEffect } from "react";
import "../../Style/Leftbar.css";

import excel from "../../Utils/Spreadsheet_icon.svg";
import chatgpt from "../../Utils/ChatGPT.svg";
import vscode from "../../Utils/Vs code.svg";

import figma from "../../Utils/Figma.svg";
import github from "../../Utils/Github.svg";
import mobile from "../../Utils/Mobile app.svg";
import slack from "../../Utils/Slack.svg";
import browser from "../../Utils/Browser Extension.svg";
import Wordpress from "../../Utils/Wordpress.svg";
import sopify from "../../Utils/sopify.svg";
import Web from "../../Utils/Web.svg";
import { Tooltip } from "@chakra-ui/react";

import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useColorModeValue,
 
  Heading

} from "@chakra-ui/react";

import { MdDeleteOutline, MdOutlineVerified } from "react-icons/md";
import { AiFillApi, AiFillDollarCircle, AiFillGift } from "react-icons/ai";
import { DiOpensource } from "react-icons/di";
import { BsClockHistory, BsTagFill } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export const data = [
  {
    logo: excel,
    name: "Spreadsheet",
  },
  // {
  //   logo: chatgpt,
  //   name: "ChatGPT (Plugins)",
  // },
  {
    logo: Web,
    name: "Web",
  },
  {
    logo: vscode,
    name: "VS Code",
  },
  {
    logo: github,
    name: "Github",
  },
  {
    logo: slack,
    name: "Slack",
  },
  {
    logo: mobile,
    name: "Mobile app",
  },
  {
    logo: Wordpress,
    name: "Wordpress",
  },
  {
    logo: figma,
    name: "Figma",
  },
  {
    logo: browser,
    name: "Browser Extension",
  },
  {
    logo: sopify,
    name: "Shopify",
  },
];


// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Spreadsheet_icon.svg",
//   name: "Spreadsheet",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Web.svg",
//   name: "Web",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Vs%20code.svg",
//   name: "VS Code",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Github.svg",
//   name: "Github",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Slack.svg",
//   name: "Slack",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Mobile%20app.svg",
//   name: "Mobile app",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Wordpress.svg",
//   name: "Wordpress",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Figma.svg",
//   name: "Figma",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/Browser%20Extension.svg",
//   name: "Browser Extension",
// },
// {
//   logo: "https://ai-zones.b-cdn.net/Website-utilities%20/Filter/sopify.svg",
//   name: "Shopify",
// }
// ];


// let price = [
//   {

//     logo: <AiFillGift size={14} />,
//     name: "Free",
//   },
//   {
//     logo: <BsClockHistory size={14} />,
//     name: "Free trial",
//   },
//   {
//     logo: <MdOutlineVerified size={14} />,
//     name: "Freemium",
//   },
//   {
//     logo: <AiFillDollarCircle size={14} />,
//     name: "Paid",
//   },
// ];

// let other = [
//   {
//     logo: <AiFillApi size={15} />,
//     name: "API",
//   },
//   {
//     logo: <DiOpensource size={15} />,
//     name: "Open Source",
//   },
//   {
//     // logo: <DiOpensource size={15} />,
//     name: "Discount",
//   },
// ];

// export const Leftbar_home_search = ({
//   open,
//   setCount,
//   setUserInfo,
//   userinfo,
//   setPageName,
//   setFilterLoader,
//   setUserInteracted,
// }) => {
  
//     const heading = useColorModeValue("gray.900", "white");
//     const font = useColorModeValue("gray.600", "gray.400");
  
//   const [isChecked, setIsChecked] = useState(false);
//   const [checkedItems, setCheckedItems] = useState({});
//   const count =
//     userinfo?.works_with?.length +
//     userinfo?.others_features?.length +
//     userinfo?.Pricing?.length;


//   const handleFilterchange = (e) => {
//     let { value, checked, name } = e.target;
    

//      // Update checked items state
//      setCheckedItems(prev => ({ ...prev, [value]: checked }));

//      // Existing logic for setting user info...
 
//      // Check if any checkbox is checked
//      const isAnyChecked = Object.values({...checkedItems, [value]: checked}).some(item => item);
//      setUserInteracted(isAnyChecked); 

//     // setCheckedItems(prev => ({ ...prev, [value]: checked }));

//     if (checked && value) {
//       setCount((pre) => pre + 1);
//     }
//     if (!checked) {
//       setCount((pre) => pre - 1);
//     }

//     if (checked && name === "works_with") {
//       setUserInfo((prev) => {
//         return {
//           ...prev,
//           works_with: [...prev.works_with, value],
//           loading: !prev.loading,
//         };
//       });
//     } else if (checked && name === "Pricing") {
//       setUserInfo((prev) => {
//         return {
//           ...prev,
//           Pricing: [...prev.Pricing, value],
//           loading: !prev.loading,
//         };
//       });
//     } else if (checked && name === "others_features") {
//       setUserInfo((prev) => {
//         return {
//           ...prev,
//           others_features: [...prev.others_features, value],
//           loading: !prev.loading,
//         };
//       });
//     } else {
//       setUserInfo((prev) => {
//         return {
//           works_with: prev.works_with.filter((ele) => ele !== value),
//           others_features: prev.others_features.filter((ele) => ele !== value),
//           Pricing: prev.Pricing.filter((ele) => ele !== value),
//           loading: !prev.loading,
//         };
//       });
//     }
//     setPageName("filter");
//     setFilterLoader((prev) => !prev);

//   };
//   useEffect(() => {
//     library.add(fas)
//   }, []);
//   const ClearBUTTON = document.getElementsByClassName("checkbox");

//   const bordercolor = useColorModeValue("#e0e0e0e6" , "#333333"); // Example colors
//   const scrollbarColor = useColorModeValue('#e0e0e0e0', '#303030');

//   const clearFilters = () => {
//     var checkboxes = document.getElementsByClassName("checkbox");
//     for (var i = 0; i < checkboxes.length; i++) {
//       checkboxes[i].checked = false;
//     }
//     setCheckedItems({});
//     setUserInteracted(false); 
//     setUserInfo({
//       works_with: [],
//       Pricing: [],
//       others_features: [],
//       loading: false,
//     });
//     setCount(0);
//     // No need to reload the page, just update the state
//     // window.location.reload();
//   };


//   const isAnyItemSelected = () => {
//     return Object.values(checkedItems).some(value => value);
//   };

//   return (
//     <>
//       <style>
//         {`
//           .tool-logo {
//             cursor: pointer;
//             display: inline-flex;
//             align-items: center;
//             justify-content: center;
//             padding: 5px;
//             border-radius: 5px;
//             transition: background-color 0.3s;
//             border: 2px solid transparent;
//           }
//           .tool-logo:hover, .tool-logo.selected {
//             background-color: #f0f0f0;
//             border: 2px solid #007bff;
//           }
//           .clear-button {
//             cursor: pointer;
//             border: none;
//             background: none;
//             padding: 5px;
//             font-size: 30px;
//             display: flex;
//             align-items: center;
//             visibility: hidden; // Initially hidden
//           }
//           .tools-container {
//             display: flex;
//             align-items: center;
//             justify-content: space-evenly;
//             width: 80%;
//           }
//           .logo-image {
//             height: 40px;
//             object-fit: contain;
//           }
//           @media (max-width: 400px) {
//             .tool-logo {
//               // padding: 10px; /* Increase padding */
//             }
//             .logo-image {
//               height: 25px; /* Increase icon size */
//             }
//             .tools-container {
//               flex-wrap: wrap; /* Allow icons to wrap to next line */
//               justify-content: center; /* Center align if they wrap */
//             }
//           }
          
//         `}
//       </style>
//       <Box >

//       {/* <Heading  textAlign="Left" as="h2"  fontSize="lg" color='blue.400'  my={2}>
//       Platform-Specific AI Tools
//              </Heading>
//             <Text as="h3"fontSize="15px" color={font} mb={4}>
//            Discover AI Tools That Integrate Seamlessly with Your Preferred Platform
//             </Text> */}
//       {/* <Heading  textAlign="Left"  lineHeight="shorter" size="16px" color={heading} my={4}>
             
//       Diverse AI Tools Across Various Platforms
//           </Heading> */}
//           <Flex  bg={useColorModeValue("#ffffff", "#1e1e1e")} borderRadius={10} alignItems="center" justifyContent="center" p={2}>
//             <div className="tools-container">
//               {data.map((el, i) => (
//                 <Tooltip key={i} label={el.name} placement="top">
//                   <div
//                     className={`tool-logo ${checkedItems[el.name] ? 'selected' : ''}`}
//                     onClick={() => handleFilterchange({ target: { value: el.name, checked: !checkedItems[el.name], name: "works_with" } })}
//                   >
//                     <Image className="logo-image" src={el.logo} alt={el.name + " logo"} />
//                   </div>
//                 </Tooltip>
//               ))}
//             </div>
//             {isAnyItemSelected() && (
//               <Box borderRadius={5} bg="#1D9BF0" boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)" >
//               <button  className="clear-button" onClick={clearFilters} style={{ visibility: 'visible' }}>
//                 <MdDeleteOutline color="white" />
//               </button>
//               </Box>
//             )}
//           </Flex>
//         </Box>
     
//     </>
//   );
// };




  export const Leftbar_home_search = ({ open, updateFilters }) => {
    // State for each category of filters
    const [selectedWorks, setSelectedWorks] = useState([]);
    const [selectedPricing, setSelectedPricing] = useState([]);
    const [selectedOthers, setSelectedOthers] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const scrollbarColor = useColorModeValue('#e0e0e0e0', '#303030');
  
    const heading = useColorModeValue("gray.900", "white");
    const font = useColorModeValue("gray.600", "gray.400");
  
    // const clearAllFilters = () => {
    //   setSelectedWorks([]);
    //   setSelectedPricing([]);
    //   setSelectedOthers([]);
    //   updateFilters({ works_with: [] });
    //   setIsChecked(false); // Assuming this state is used to manage the checkbox states
    // };
    

    const updateFilterSelection = (isChecked, value, currentSelection, setSelection, category) => {
      let updatedSelection = isChecked
        ? [...currentSelection, value]
        : currentSelection.filter(item => item !== value);


        setSelection(updatedSelection);

        let tempFilters = {
          works_with: selectedWorks,
          // Pricing: selectedPricing,
          // others_features: selectedOthers
        };
    
        tempFilters[category] = updatedSelection;
        updateFilters(tempFilters);
      };

  // const count =
  //   userinfo?.works_with?.length +
  //   userinfo?.others_features?.length +
  //   userinfo?.Pricing?.length;


  const handleFilterChange = (itemName) => {
    const newCheckedItems = { ...checkedItems, [itemName]: !checkedItems[itemName] };
    setCheckedItems(newCheckedItems);

 // Update filters based on current selection
 const worksWithFilters = Object.keys(newCheckedItems).filter(item => newCheckedItems[item]);
 updateFilters(prevFilters => ({
   ...prevFilters,
   works_with: worksWithFilters
 }));
};

const clearAllFilters = () => {
  setCheckedItems({});
  updateFilters(prevFilters => ({
    ...prevFilters,
    works_with: []
  }));
};

  // Calculate the total count of selected filters
  //const totalFiltersCount = selectedWorks.length + selectedPricing.length + selectedOthers.length;

  useEffect(() => {
    library.add(fas);
  }, []);

  const ClearBUTTON = document.getElementsByClassName("checkbox");

  // const bordercolor = useColorModeValue("#e0e0e0e6" , "#333333"); // Example colors



  const isAnyItemSelected = () => Object.values(checkedItems).some(value => value);

  const bordercolor = useColorModeValue("#e0e0e0e6" , "#333333"); 

  return (
    <>
      <style>
        {`
          .tool-logo {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border-radius: 5px;
            transition: background-color 0.3s;
            border: 2px solid transparent;
          }
          .tool-logo:hover, .tool-logo.selected {
            background-color: #f0f0f0;
            border: 2px solid #007bff;
          }
          .clear-button {
            cursor: pointer;
            border: none;
            background: none;
            padding: 5px;
            font-size: 30px;
            display: flex;
            align-items: center;
            visibility: hidden; // Initially hidden
          }
          .tools-container {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 80%;
          }
          .logo-image {
            height: 40px;
            object-fit: contain;
          }
          @media (max-width: 400px) {
            .tool-logo {
            padding: 10px; /* Increase padding */
            }
            .logo-image {
              height: 25px; /* Increase icon size */
            }
            .tools-container {
              flex-wrap: wrap; /* Allow icons to wrap to next line */
              justify-content: center; /* Center align if they wrap */
            }
          }
          
        `}
      </style>
      <Box >

      {/* <Heading  textAlign="Left" as="h2"  fontSize="lg" color='blue.400'  my={2}>
      Platform-Specific AI Tools
             </Heading>
            <Text as="h3"fontSize="15px" color={font} mb={4}>
           Discover AI Tools That Integrate Seamlessly with Your Preferred Platform
            </Text> */}
      {/* <Heading  textAlign="Left"  lineHeight="shorter" size="16px" color={heading} my={4}>
             
      Diverse AI Tools Across Various Platforms
          </Heading> */}
          
          <Flex  bg={useColorModeValue("#ffffff", "#1e1e1e")} border={"1px"} color={bordercolor} borderStyle="solid"borderRadius={10} alignItems="center" justifyContent="center" p={2}>
            <div className="tools-container">
              {data.map((el, i) => (
                <Tooltip key={i} label={el.name} placement="top">
                  <div
                    className={`tool-logo ${checkedItems[el.name] ? 'selected' : ''}`}
                    onClick={() => handleFilterChange(el.name)}
                  >
                    <Image className="logo-image" src={el.logo} alt={el.name + " logo"} />
                  </div>
                </Tooltip>
              ))}
            </div>
            {isAnyItemSelected() && (
              <Box borderRadius={5} bg="#1D9BF0" boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)" >
              <button  className="clear-button" onClick={clearAllFilters} style={{ visibility: 'visible' }}>
                <MdDeleteOutline color="white" />
              </button>
              </Box>
            )}
          </Flex>
        </Box>
     
    </>
  );
};
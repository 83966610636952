import React, { useEffect, useState, useRef } from "react";
import { BsChatText, BsGrid } from "react-icons/bs";
import { MdOutlineCategory } from "react-icons/md";
import { ImList2 } from "react-icons/im";
import style from "../../Style/Subnav.module.css";
import { FaHome } from 'react-icons/fa';
import {Helmet, HelmetProvider} from "react-helmet-async";
import style2 from "../../Style/Landing.module.css";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CgMenuGridO } from "react-icons/cg";
import SupportModal from './SupportModal';
import AdBanner from "./adbanner.jsx"; 

import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  useColorMode,
  IconButton,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  Spinner, 
 Stack, Heading, Center, Image, Divider, Highlight
} from "@chakra-ui/react";

import NewsletterPopup from './Newsletterpopup';
import Tour from './tour';
import { Leftbar} from "./LeftBar";
import { Leftbar_home} from "./LeftBar_home";
import { LIstView_home } from "./LIstPage_home";
import { LIstView } from "./LIstPage";
import { GridPage } from "./GridPage";
import { Footer } from "./Footer";
import { LandingRight } from "../LandingRight";
import { CurtedCollection_home } from "../CurtedCollection_home.jsx";
import Footer_options  from "./Footer_home";
import BlogLandingPage_home from "./Updates_home";




import { ChevronDownIcon } from "@chakra-ui/icons";

import { LandingPage } from "../../Pages/LandingPage";
import LoadingAnimation from '../LoadingAnimation';

import axios from "axios";
import { Sort } from "./Sort";

import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import notification from "../Toast";
import { removeCategoryItem, setFilterCategory } from "../../Redux/action";
import { useParams, useNavigate } from "react-router-dom";
import { searchItem } from "../../Redux/action";
// import { Link } from "react-router-dom";
// import LoginModel from "../model/LoginModel";
import { useSearchParams } from "react-router-dom";
import HandleGoogle from '../../Firebase/handleGoogleAuth';
import Slideshow from "./Crousel";

import { useLocation } from 'react-router-dom';
// import lightLogo from "../../Utils/LOGO-AIZONES-white.svg";
// import darkLogo from "../../Utils/LOGO-AIZONES-black.svg";
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  breakpoints: {
    sm: '420px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
});
export const SubNavbar = () => {
  // const location = useLocation();
  const { categories } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [list, setlist] = useState(true);
  const { colorMode } = useColorMode();

const lightLogo = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-white.svg";
const darkLogo = "https://ai-zones.b-cdn.net/Website-utilities%20/LOGO-AIZONES-black.svg";

const logo = colorMode === 'light' ? darkLogo : lightLogo;



  // With this new state variable
const [viewMode, setViewMode] = useState('small grid'); 
  let [count, setCount] = useState(0);
  const [open, setopen] = useState(false);
  const [showMenu, setMenu] = useState(false);
  let { id } = useParams();
  const { searchReducer } = useSelector((store) => store.searchReducer);
  const [isFetchingCategories, setIsFetchingCategories] = useState(false);

  const location = useLocation();

  const color = useColorModeValue("gray.600", "gray.400");
  const textcolor=useColorModeValue("#444444", "#cccccc");
  const boxShadowColor = useColorModeValue('rgba(0, 0, 0, 0.2)', 'rgba(255, 255, 255, 0.2)');
  const bg=useColorModeValue("#ffffff", "#222222");
  const borderColor=useColorModeValue("#e0e0e0", "#444");
  const filtercolor=useColorModeValue("white", "#464444");
  const categorycolor=useColorModeValue("#ffffff", "#222222");
  const categorylistcolor=useColorModeValue("white", "#222222");
  const font = useColorModeValue("gray.600", "gray.400");
  const highlight=useColorModeValue("#1D9BF0" , "#1D9BF0"); 
  const heading = useColorModeValue("gray.800", "#eeeeee");



  const [sponsoredTools, setSponsoredTools] = useState([]);
  const currentAdIndex = useRef(0);
  const TOOLS_BEFORE_AD = 9;

  // Fetch Sponsored Tools data
  // useEffect(() => {
  //   const fetchSponsoredTools = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
  //       setSponsoredTools(response.data);
  //     } catch (error) {
  //       console.error("Error fetching sponsored tools:", error);
  //     }
  //   };

  //   fetchSponsoredTools();
  // }, []);

  // // Get the next ad to display
  // const getNextAd = () => {
  //   if (sponsoredTools.length === 0) return null;
  //   const ad = sponsoredTools[currentAdIndex.current];
  //   currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length;
  //   return ad;
  // };
  /////////////////////////////sponsor tool till above/////////////////////////////////////////

  // const [loginModelOpen, setloginModelOpen] = useState(false);
  // const handleOpen = () =>{
  //   setloginModelOpen(true)
  // }
  // const handleClose = () =>{
  //   setloginModelOpen(false)
  // }

  const handleActionButtonClick = async () => {
    if (!userData) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, onOpen);
    } else {
      // If the user is already logged in, just open the modal
      onOpen();
    }
  };

  useEffect(() => {
    // Check if the screen width is less than or equal to 700px
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setViewMode('list'); // Set to 'list' view on mobile devices
      } else {
        setViewMode('small grid'); // Set to 'small grid' view on larger devices
      }
    };

    // Call the function on component mount
    handleResize();

    // Optional: Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ------------------------------
  const userData = useSelector((state) => state.userReducer.loginData);
  const { searchItems } = useSelector((state) => state.searchReducer);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteLoading, setDeleteLoading] = useState(false);
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  const [cat, setcatlist] = useState([]);
  // const [Category, setcat] = useState([]);
  // //console.log('Category: ', Category);
  const [message, setMessage] = useState("");
  const [pageName, setPageName] = useState("");
  const [FilterLoader, setFilterLoader] = useState(false);
  const [sort, setSort] = useState("");
  const [sortLoader, setSortLoader] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const categoriesFetchedRef = useRef(false);
  const categoriesDataRef = useRef(null);
  const [newToolsPage, setNewToolsPage] = useState(1);
const [hasMoreNewTools, setHasMoreNewTools] = useState(true);


  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');
 

  const [userInteracted, setUserInteracted] = useState(false); // New state to track user interaction
  const [sortedData, setSortedData] = useState([]); // State to hold sorted data


  let sorted = [
    // ...firstThreeFeatured,
    // ...remainingFeatured,
    ...data,
  ];


const [newTools, setNewTools] = useState([]);
const [loadingNewTools, setLoadingNewTools] = useState(false);
const [errorNewTools, setErrorNewTools] = useState(null);

// useEffect(() => {
//   const fetchNewestTools = async () => {
//     try {
//       setLoadingNewTools(true);
//       const response = await axios.get(`${process.env.REACT_APP_API}/data/get_new_data_homepage`);
//       setNewTools(response.data.data); // Set the newest tools data
//       setLoadingNewTools(false);
//     } catch (error) {
//       console.error("Error fetching newest tools:", error);
//       setErrorNewTools("Failed to load newest tools");
//       setLoadingNewTools(false);
//     }
//   };

//   fetchNewestTools();
// }, []);

const [isLoadingMore, setIsLoadingMore] = useState(false);
const fetchNewestTools = async (page = 1) => {
  try {
    if (page === 1) {
      setLoadingNewTools(true); // Only show main loading on initial load
    } else {
      setIsLoadingMore(true); // Show loading only in button for subsequent pages
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/data/get_new_data_homepage?page=${page}`);
    
    if (page === 1) {
      setNewTools(response.data.data);
      setLoadingNewTools(false);
    } else {
      setNewTools(prev => [...prev, ...response.data.data]);
      setIsLoadingMore(false);
    }
    
    setHasMoreNewTools(response.data.pagination?.hasNextPage || false);
  } catch (error) {
    console.error("Error fetching newest tools:", error);
    setErrorNewTools("Failed to load newest tools");
    setLoadingNewTools(false);
    setIsLoadingMore(false);
  }
};


const handleLoadMoreNewTools = () => {
  const nextPage = newToolsPage + 1;
  setNewToolsPage(nextPage);
  fetchNewestTools(nextPage);
};


useEffect(() => {
  fetchNewestTools(1);
}, []);


  const [userinfo, setUserInfo] = useState({
    works_with: [],
    Pricing: [],
    loading: false,
    others_features: [],
  });

  const getData = async (page) => {
    if (!userInteracted) {
      console.log('Skipping API call: User has not interacted yet.');
      return;
  }
    setShowLoader(true);
          let payload = {};

    if (userinfo.Pricing.length > 0) {
      payload.Pricing = userinfo.Pricing;
    }
    if (userinfo.works_with.length > 0) {
      payload.works_with = userinfo.works_with;
    }
    if (userinfo.others_features.length > 0) {
      payload.others_features = userinfo.others_features;
    }
    if (categories.length > 0) {
      payload.Category = [...new Set(categories)];
    }
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API}/data/get?sort=${sort}&page=${page || 1}`,
        payload
      );
      setData((pre) => [...pre, ...res.data.data]);
      console.log('++++++');
      setShowLoader(false);
      if (res.data.data && res.data.data.length === 0) {
        // getData(page+1)
      }
    } catch (err) {
      setShowLoader(false);
      //console.log(err);
    }
  };

  

  const handleCategoryClick = async () => {
    setIsFetchingCategories(true);
   
    // If we have already fetched the categories data, don't fetch it again.
    if (categoriesFetchedRef.current) {
      setIsFetchingCategories(false);
      setcatlist(categoriesDataRef.current);
     
      return;
    }

    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/cat/get`);
      categoriesDataRef.current = res.data; // Store fetched data in the ref
      setcatlist(res.data); // Update state to re-render with new data
      categoriesFetchedRef.current = true; // Indicate that data has been fetched
      setIsFetchingCategories(false);
      console.log("Fetched categories data: ", res.data);
      
    } catch (error) {
      // Handle the error appropriately
      console.error("Error fetching categories:", error);
     
    }
  };

  
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname !== "/search") {
      dispatch(searchItem({}))
      // searchItems = {};

    }
  }, []);

  // useEffect(() => {
  //   getCat();
  //   console.log(count, 'cc');
  // }, []);

  useEffect(() => {
    if (pageName === "filter") {
      setPage(1);
      setData([]);
      setPageName("");
    }
    if (pageName === "sort") {
      setPage(1);
      setData([]);
      setPageName("");
    }

    if (deleteLoading) {
      setDeleteLoading(false);
      window.location.reload();
    }

    if (pageName === "") {
      getData(page);
    }
  }, [page, pageName, deleteLoading]);

  //This was removed for Double page load

  // useEffect(() => {
  //   getData(1);
  //   localStorage.setItem("page", page);
  // }, [...new Set(categories)]);
  //console.log("categories: ", categories);

  const infinitScroll = async () => {
    try {
      
      if (
        (window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight) && window.location.pathname !== "/search"
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", infinitScroll);
    return () => window.removeEventListener("scroll", infinitScroll);
  }, []);

  const handleCat = (el, i) => {
    // setcat((pre) => [...pre, el]);
    dispatch(setFilterCategory(el));
    

    setPageName("filter");
    setFilterLoader((prev) => !prev);
    setUserInteracted(true); // Set userInteracted to true
  };

  const removeItem = (el, i) => {
    //console.log("i: ", i);
    // Category.splice(i, 1);
    dispatch(removeCategoryItem(i));
    setPageName("filter");
    setUserInteracted(false);
    // setFilterLoader((prev) => !prev);
  };

  const sendMessage = () => {
    const payload = {
      message: message,
    };
    const token = userData.data;
    axios
      .post(`${process.env.REACT_APP_API}/message/add`, payload, {
        headers: { token },
      })
      .then((res) => {
        notification("success", res.data.msg);
        onClose();
      });
  };

  const setcat = (item) => {
   
    dispatch(setFilterCategory(item));
    setUserInteracted(true);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth", // Use 'auto' for instant scrolling, or 'smooth' for smooth scrolling
    });

  };
  const side = document.getElementsByClassName('sidebar');
  const sidebarWidth =  side[0]?.offsetWidth;



  useEffect(()=> {
    window.location.pathname === "/search" && setopen(false)
//  setopen(true)

  })

  const navigate = useNavigate();

  const reload = ()=> {
    navigate("/")
    window.location.reload()
  }



  const [loading, setLoading] = useState(false);
const [error, setError] = useState(null);

const [currentPage, setCurrentPage] = useState(1);

const [isLoadingMoreCategories, setIsLoadingMoreCategories] = useState(false);
const [hasMoreCategories, setHasMoreCategories] = useState(true);
const [groupedTools, setGroupedTools] = useState({});

const [categoryPages, setCategoryPages] = useState({}); // Tracks the current page for each category
const [loadingMore, setLoadingMore] = useState({}); // Tracks loading state for each category


  // ///////////////////////////tool by category here  

const loadMoreCategories = async () => {
    try {
      setIsLoadingMoreCategories(true);
      const response = await axios.get(`${process.env.REACT_APP_API}/data/toolsbycategory_homepage?page=${currentPage + 1}`);
      // Update the state to include the newly loaded categories
      setGroupedTools(prev => ({ ...prev, ...response.data }));
      setCurrentPage(currentPage + 1);
      
      // Check if the response contains fewer categories than the maximum limit, indicating no more pages
      if (Object.keys(response.data).length < 4) {
        setHasMoreCategories(false);
      }
    } catch (error) {
      console.error('Error loading more categories:', error);
    } finally {
      setIsLoadingMoreCategories(false);
    }
  };
  


  
  const fetchGroupedTools = async (category, nextPage = 1) => {
    try {
      setLoadingMore(prev => ({ ...prev, [category]: true }));
      // Assuming `category` holds the mainCategoryTitle
      const response = await axios.get(`${process.env.REACT_APP_API}/data/toolsbycategory_homepage/${encodeURIComponent(category)}?page=${nextPage}`);
      
      // Update state with new tools
      setGroupedTools(prev => ({
        ...prev,
        [category]: [...(prev[category] || []), ...response.data]
      }));
      // Update the page number for the category
      setCategoryPages(prev => ({ ...prev, [category]: nextPage }));
      setLoadingMore(prev => ({ ...prev, [category]: false }));
    } catch (error) {
      console.error('Error fetching more tools:', error);
      setLoadingMore(prev => ({ ...prev, [category]: false }));
    }
  };
  
  useEffect(() => {
    const fetchGroupedTools = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API}/data/toolsbycategory_homepage`);
        
        setGroupedTools(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching grouped tools:', error);
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchGroupedTools();
  }, []);
  
  if (loading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center" flexDirection="column">
      <Image src={logo} boxSize="200px" alt="Logo" /> {/* Conditional Logo Display */}
      <Spinner size="xl" color="blue.500" thickness="3px" speed="0.65s" emptyColor="gray.200" />
    </Flex>
    );
  }
  
  if (error) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Text>Error: {error}</Text>
      </Flex>
    );
  }



  


  const categorySubheadings = {
    "Image": "Pixel Perfection Awaits – Unleash AI to Transform Your Images with Advanced Creative Algorithms for Digital Artistry.",
    "Design":  "Design with AI – Where Every Detail Dazzles, leveraging Generative AI for instant unique designs and creative intelligence.",
    "Life Assistant": "AI Life Assistant – Simplify Your Day, The Smart Way, with intelligent design assistants that morph text into vivid visuals.",
    "Miscellaneous": "Discover AI's Eclectic Edge – Where Variety Meets Innovation in AI image generators, audio generators, and more.",
    "Video":  "AI Video Vanguards – Craft Stunning Scenes with Ease using AI-powered marketing assistants and deep learning models.",
    "Developer Tools": "Code Smarter, Not Harder – AI Tools for Developers enhance Productivity in Various Industries with advanced algorithms and Machine Learning.",
    "Writing": "Write with AI Wisdom – Elevate Your Words Instantly with the best AI text generators for unparalleled content creation.",
    "Generative AI": "AI, the Creative Muse – Generate Brilliance on Demand with AI image and art generators transforming text to image seamlessly.",
    "Audio & Music": "Harmonize with AI – Elevate Your Audio Experience with AI Audio Generators, merging technology and music for unparalleled soundscapes.",
    "AI Bot": "AI Bots – Engage with Digital Intelligence, utilizing Natural Language Understanding (NLU) to power conversational agents for diverse applications.",
    "3D": "3D Reimagined – AI-Powered Visions in Every Dimension, exploring new horizons in 3D Generations with AI’s Advanced Creative Algorithms.",
    "Business": "AI for Business – Smart Tools for Smarter Decisions, optimizing operations with AI Text Generators for Marketing & Sales, and Business Analytics."
}


  const metaTitle = "AI Zones: Discover, Compare & Explore the Best AI Tools ";
  document.title = metaTitle;
  const metaDescription = "AI Zones: Discover the best AI tools directory at AI Zones. Find top-rated AI apps, software, and services to boost productivity. Explore daily updates, expert reviews, and personalized recommendations for AI solutions tailored to your needs.";



  return (
    <HelmetProvider>
    <Helmet>
      {/* Meta tags */}
      <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Other meta tags, such as og:tags, can be added here */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}`} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= '../Utils/AI-Zones_Cover.png' />
        <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${process.env.REACT_APP_PUBLIC_URL}`} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />
  

    </Helmet>
    <>
    <Box>
      <Box
        hidden={id}
        // bg={useColorModeValue("#ffffff", "#222222")}
        // bgGradient={useColorModeValue(
        //   "linear(to right,  #F5F9FD, #F5F9FD )", // light mode complementary gradient
        //   "linear(to right, #222222, #303030)" // dark mode grey gradient
        // )}
       
        className={style.subnav}
        fontFamily="'Lato', Arial, Helvetica, sans-serif"
        // borderBottom="1px"
        // borderColor={useColorModeValue("#cfd8dc", "#444")}
      >
        
        <Flex justifyContent="space-between" className={style.filterbutton} alignItems="center" >
          <Flex alignItems="center" gap="20px">
            <Flex
              boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1)"
              position="relative"
              fontWeight="400"
              alignItems="center"
              // visibility={window.location.pathname !== "/search" ? "visible" : "hidden"}
              // justifyContent={open ? "space-between" : "center"}
              // w={open ? "142px" : ""}
              ml="15px"
              h="100%"
              borderRadius="4px"
              borderWidth="1px"
              padding={'5px'}
              backgroundColor={bg}
              display={{ base: "none", sm: "Flex" }} 
              
            >
              <Box >
              <TbAdjustmentsHorizontal size={20} onClick={() => setopen(!open)}/>
              {count > 0 && (
             <Text
             onClick={() => setopen(!open)}
              position="absolute"
              top="-9px" // Adjust these values as needed
              right="-7px"
              fontSize="10px"
              bg="#1D9BF0"
              color="white"
              borderRadius="100%"
              px="4px" // Padding for the circle shape
              textAlign="center"
            >
            {count}
          </Text>
        )}
              </Box>
              {open ? (
                <Text
                  onClick={() => setopen(!open)}
                  cursor="pointer"
                  fontSize="14px"
                  lineHeight="24px"
                  fontFamily="'Lato', Arial, Helvetica, sans-serif"
                  fontWeight="600"
                  letterSpacing="2.59px"
                  textTransform="uppercase"
                  marginLeft="2px"
                  padding="0 5px"                
                  
                >
                  Filters
                </Text>
              ) : (
                ""
              )}
              {(open && count > 0) ? (
                <Text
                  mb="10px"
                  w="15px"
                  h="15px"
                  alignItems="center"
                  textAlign="center"
                  justifyContent="center"
                  fontSize="10px"
                  bg="#1D9BF0"
                  color="white"
                  borderRadius="100%"
                  position="absolute"
                  right="-2"
                  top="-2"
                >
                  {count}
                </Text>
              ) : (
                ""
              )}
            </Flex>

            <Box ml={open ? "23px" : "44px"}>
              <Menu bg={filtercolor}>

                
              {/* {window.location.pathname === "/search" && (
        
              <IconButton
              aria-label="Home"
             icon={<FaHome />}
            // isRound
            onClick={reload} // Added onClick event here
             />
            )} */}
                <MenuButton
                  display={{ base: "none", md: "block" }} 
                  px={3}
                  py="3px"
                  borderRadius="4px"
                  borderWidth="1px"
                  _hover={{ bg: "" }}
                  fontSize="14px"
                  lineHeight="24px"
                  fontWeight="400"
                  visibility={window.location.pathname !== "/search" ? "visible" : "hidden"}
                  // onMouseEnter={!showMenu}
                  // onMouseLeave={onClose}
                  onClick={handleCategoryClick}
                  bg={categorycolor}
                  boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)"
                >
            
                  <Flex alignItems="center" gap="5px">
                    <MdOutlineCategory /> Category <ChevronDownIcon />
                  </Flex>

                </MenuButton>
               
                <MenuList
                  bg={categorylistcolor}
                  overflow="auto"
                  whiteSpace="nowrap"
                  className={style.menulist}
                  width={"calc(100vw / 1.2) !important"} 
                  textColor={textcolor}              
                 
                >
                  {isFetchingCategories && (
                  <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                 size='xl'

                  />
                )}
                  
                  <Flex fontSize="13px" gap="10" p="15px"
                  style={{
                  }}
                  
                  >
                    {cat?.map((el, i) => (

                      
                      <Box
                      
                        key={i}
                        style={{
                          color: i % 2 == 0 ? "rgb(175 155 155 / 89%)" : "rgb(191 178 178)",                          
                          background: i % 2 == 0 ? "transparent" : "rgb(89 76 76 / 10%)",                          
                          width: "700px",
                          padding: "2%",
                         
                        }}
                      >
                         <Text
                          bg=""
                          mt="5px"
                          mb="5px"
                          fontSize="17px"
                          fontWeight="700"
                          lineHeight="16px"
                         color={textcolor}
                        >
                          {el.Title}
                        </Text>
                        <Flex flexDirection="column">
                          {el.Category.map((e, ind) => (
                            <Text
                              key={ind}
                              cursor="pointer"
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight="31.5px"
                              color={textcolor}
                              _hover={{
                                fontSize: "15px",
                                color: "#5e6b9b"
                              }}
                              onClick={() => {
                                handleCat(e, ind);
                                // onClose();
                              }}
                            >
                              {e}
                            </Text>
                          ))}
                        </Flex>
                      </Box>
                    ))}
                  </Flex>
                </MenuList>
              </Menu>
            </Box>

            <Box className={style.xscrollbox}>
  <Flex
    position="relative"
    alignItems="center"
    gap="15px"
    h="100%"
    overflowX="scroll"
    whiteSpace="nowrap"
    className={style.xscroll}
    w="100%"
  >
    {[...new Set(categories)]?.map((el, i) => (
      <Flex
        key={i}
        px={2}
        py="2.5px"
        height="fit-content"
        borderRadius="10px"
        borderWidth="1px"
        alignItems="center" // This ensures vertical alignment is centered
        justifyContent="space-between"
        textAlign="center"
        gap="5px"
        bg={bg}
        position="relative"
        display={{ base: "none", md: "flex" }} // Changed from "block" to "flex" to apply Flexbox properties
      >
       
        <Text fontSize="14px" fontWeight="400" lineHeight="24px">
          {el}
        </Text>
        <AiOutlineClose
          size={12}
          cursor="pointer"
          onClick={() => removeItem(el, i)}
        />
      </Flex>
    ))}
  </Flex>
</Box>
          </Flex>

         
          <Flex className={style.margin} alignItems="center"  >
             {/* <Button bg="" cursor="pointer" onClick={() => setlist(true)}>
              <ImList2 fontWeight="300" />
            </Button> */}
            {/* .smallbox */}
            {/* <Button bg="" cursor="pointer" onClick={() => setlist(false)}>
              <BsGrid />
            </Button>  */}
<Box bg={bg}  size="xs"  borderColor={borderColor} borderRadius="5px" boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)"  >
<Flex align="center" px={5} > 
<Text  display={{ base: "none", md: "block" }} px={2}>Views:</Text>

  <Button  display={{ base: "none", md: "block" }} bg="" cursor="pointer" onClick={() => setViewMode('small grid')} aria-label="Switch to small grid view">
    {/* Replace with an appropriate icon for "xy" view */}
    <CgMenuGridO size="22px" />   
  </Button>

  <Button display={{ base: "none", md: "block" }} bg="" cursor="pointer" onClick={() => setViewMode('list')} aria-label="Switch to list view">
    <ImList2 fontWeight="300" />   
  </Button>

  <Button  display={{ base: "none", md: "block" }} bg="" cursor="pointer" onClick={() => setViewMode('grid')} aria-label="Switch to full cover grid view">
    <BsGrid />    
  </Button>
  </Flex>
  </Box>

            {/* --------------------------------------- */}
            {/* <Flex> */}

            <Sort 
            setSort={setSort} 
            setPageName={setPageName} 
            setSortLoader={setSortLoader} 
             setUserInteracted={setUserInteracted} // Pass setUserInteracted to Sort component
           />

            {/* ----------------------------------------------- */}
          {/* </Flex> */}
          </Flex>
        </Flex>
      </Box>

      <Flex mt= {{ base: "5px", md: "60px" }} >
      
      <Box>
      {/* <Heading as="h1" size="xl">
        Welcome to AI Zones!
      </Heading> */}
      {/* <NewsletterPopup />  */}
       {/* <Tour/> */}
       </Box>
       
    {<Stack w="100%" position="relative" mt={window.innerWidth < 600 && "70px"}>
      <Flex
        color={color}
        className={(open) ? style2.opengap : style2.closegap}
      >
        <Stack w="fit-content">
          {location.pathname !== '/search' && ( 
          <Leftbar
            open={open}
            userinfo={userinfo}           
            setUserInfo={setUserInfo}
            setPageName={setPageName}
            setFilterLoader={setFilterLoader}
            setCount={setCount}
            setUserInteracted={setUserInteracted}
            currentSort={sort} 
                      />
          )}
        </Stack>

        <Stack className={(open ? style2.openslide : style2.closeslide)} w="100%">
              <Box w="100%">
                <Flex className={open ? style2.opengap : style2.closegap} >
                  <Stack className={style2.landingbox}>
                    <Box w="100%">
                      <Slideshow searchName={query} />
                     

                      <Divider  />

                      
                      <Leftbar_home
                      open={open}
                      userinfo={userinfo}           
                      setUserInfo={setUserInfo}
                      setPageName={setPageName}
                      setFilterLoader={setFilterLoader}
                      setCount={setCount}
                      setUserInteracted={setUserInteracted}
                      currentSort={sort} 
                      />

                  

                       {!userInteracted && (
                       <Divider my={4} />  
                       )}    

{!userInteracted && (             
                      <BlogLandingPage_home/>
                      )}  

<Divider my={4} />
                
                      
                        {/* {!userInteracted && (             
                      <BlogLandingPage_home/>
                      )}   */}
                     

                      

                       {/* Newly Submitted Tools Section */}
                       {!userInteracted && (
  <Box mb={6}>
    <Flex justifyContent="space-between" alignItems="center">
      {/* Heading */}
      <Heading as="h2" textAlign="left" size="md" color="#1D9BF0" my={2}>
        Newly Submitted Tools
      </Heading>

      {/* Submit Button */}
      <a
          href={`${process.env.REACT_APP_PUBLIC_URL}/submit`}
          target="_blank"
          
          style={{ textDecoration: "none" }}
        >
          <Text
            // color="blue.500"
            fontSize="sm"
            fontWeight="bold"
            _hover={{ textDecoration: "underline", color: "blue.600" }}
            _active={{ color: "blue.700" }}
            textDecoration={"underline"}
          >
            Submit a tool ➡️
          </Text>
        </a>
    </Flex>

    {/* Subheading */}
    <Text className="subheading" mb={3} fontSize="15px" color="font">
      Explore the latest tools submitted to AI Zones.
    </Text>
  
   

    {loadingNewTools ? (
      <Center>
        <Spinner size="lg" color="blue.500" />
      </Center>
    ) : errorNewTools ? (
      <Text color="red.500">{errorNewTools}</Text>
    ) : viewMode === 'small grid' ? (
      <LIstView_home
        data={newTools}
        setPageName={setPageName}
        setFilterLoader={setFilterLoader}
        showLoader={showLoader}
        setDeleteLoading={setDeleteLoading}
        userinfo={userinfo}
        setcat={setcat}
      />
    ) : viewMode === 'grid' ? (
      <GridPage
        data={newTools}
        setPageName={setPageName}
        setFilterLoader={setFilterLoader}
        showLoader={showLoader}
        setDeleteLoading={setDeleteLoading}
        userinfo={userinfo}
        setcat={setcat}
      />
    ) : (
      <LIstView
        data={newTools}
        setPageName={setPageName}
        setFilterLoader={setFilterLoader}
        showLoader={showLoader}
        setDeleteLoading={setDeleteLoading}
        userinfo={userinfo}
        setcat={setcat}
      />
                  
    )}
   <Center padding={3}>
  {hasMoreNewTools && (
 <Button
 ml={3}
 onClick={handleLoadMoreNewTools}
 isLoading={isLoadingMore}
 loadingText="Loading more..."
 disabled={isLoadingMore}

>
 See more
</Button>
)}
</Center>

  </Box>
)}


{/* 
{!userInteracted && (
                       <Divider my={7} />
                   )} */}

                   {/* ///////////////////////////////////////////ad banner here */}

{/* 
                                            {!userInteracted && (
                      <AdBanner adTool={getNextAd()} />
                   )}   */}

                     {!userInteracted && (
                     <CurtedCollection_home/>  
                      )}



                      {/* ///////////////////////// tools from different category////////////////// */}

<Divider my={4} />


                   
                      <Box className={style2.smallScreenList}>

            {userInteracted ? (
             viewMode === 'small grid' ? (
                 <LIstView_home
                  data={sorted}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  showLoader={showLoader}
                  setDeleteLoading={setDeleteLoading}
                  userinfo={userinfo}
                  setcat={setcat}
                />
                ) : viewMode === 'grid' ? (
                  <GridPage
                  data={sorted}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  showLoader={showLoader}
                  setDeleteLoading={setDeleteLoading}
                  userinfo={userinfo}
                  setcat={setcat}
                />                
                ) : (
               
                  <LIstView
                   data={sorted}
                   setPageName={setPageName}
                   setFilterLoader={setFilterLoader}
                   showLoader={showLoader}
                   setDeleteLoading={setDeleteLoading}
                   userinfo={userinfo}
                   setcat={setcat}
                 />
                 )
                 ) : (
              // Render grouped tools with categories and "See more" buttons when user hasn't interacted
              Object.entries(groupedTools).map(([category, tools]) => (
                <Box key={category} mb={6}>
                  <Heading as="h2" textAlign="left" size="md" color='#1D9BF0'  my={2}>
                    {category}
                  </Heading>
                  <Text className="subheading" mb={3} fontSize="15px" color="font" >
                  {categorySubheadings[category]} 
                  </Text>
                  {
                  viewMode === 'small grid' ? (
                 <LIstView_home
                  data={tools}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  showLoader={showLoader}
                  setDeleteLoading={setDeleteLoading}
                  userinfo={userinfo}
                  setcat={setcat}
                />
                ) : viewMode === 'grid' ? (
                  <GridPage
                  data={tools}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  showLoader={showLoader}
                  setDeleteLoading={setDeleteLoading}
                  userinfo={userinfo}
                  setcat={setcat}
                />                
              
              ) : (
               
                  <LIstView
                   data={tools}
                   setPageName={setPageName}
                   setFilterLoader={setFilterLoader}
                   showLoader={showLoader}
                   setDeleteLoading={setDeleteLoading}
                   userinfo={userinfo}
                   setcat={setcat}
                 />
                 )
            }
                  <Center padding={3}>
                  <Button ml={3} 
                    onClick={() => fetchGroupedTools(category, (categoryPages[category] || 1) + 1)}
                    isLoading={loadingMore[category]}
                  >
                    See more
                  </Button>
                  </Center>
                </Box>
         ))
         )}
       </Box>
       {!userInteracted && hasMoreCategories && (
      <Center my={4}>
    <Button
      onClick={loadMoreCategories}
      isLoading={isLoadingMoreCategories}
      loadingText="Loading..."
      size="lg" // Increase the size of the button
      width="100%" // Set the button width to 100% of its parent container
      // colorScheme="teal"
      // color="white"
      // bg="#1D9BF0" // Use the color scheme for styling
      leftIcon={<AiOutlinePlusCircle />} // Add an icon to the left of the button text
      variant="outline" // Use the solid variant for a filled button style
    >
      Load More Categories
    </Button>
  </Center>
)}

{/* //////////////////////////till here/////////////////////////// */}
     </Box>
   </Stack>

   <Stack>
   <Box display="block" css={{ '@media(max-width: 1400px)': { display: 'none' } }}>
    <LandingRight />
  </Box>
</Stack>

            </Flex>
          </Box>
        </Stack>
      </Flex>

      
      {
      (window.location.pathname !== "/search")  && <Footer
        count={count}
        setSort={setSort}
        setSortLoader={setSortLoader}
        setUserInfo={setUserInfo}
        setPageName={setPageName}
        setFilterLoader={setFilterLoader}
        cat={cat}
        setcat={setcat}
        setCount={setCount}
        setUserInteracted={setUserInteracted}
        currentSort={sort} 
      />
      }
    </Stack>}
    
        {/* )} */}
        </Flex>
      {/* {loginModelOpen? <LoginModel onCloseOuter={handleClose}/> : null} */}
{/* //////////////////////////////////// */}
      <Box
        cursor="pointer"
        // onClick={onOpen}
        onClick={() =>{
          if(!userData){
            handleActionButtonClick();
            return;
          }
          onOpen();
        }}
        p="10px"
        position="fixed"
        bottom="8%"
        right="2%"   
        zIndex="200"
        bg="black"
        borderRadius="10px"
        boxShadow={`0px 4px 10px ${boxShadowColor}`} 
      >
        <BsChatText color="white" size={25} />
      </Box>
      <SupportModal
          userData={userData}
          handleActionButtonClick={handleActionButtonClick}
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
          setMessage={setMessage}
          sendMessage={sendMessage}
          boxShadowColor={boxShadowColor}
        />
    </Box>
   < Footer_options/>

  </>
    </HelmetProvider>
  );

};

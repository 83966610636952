import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import style from "../Style/Featured.module.css";
import { RiShareBoxFill } from "react-icons/ri";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import notification from "./Toast";

export const Featured = () => {  
  const [email, setEmail] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const borderColor = useColorModeValue("#e0e0e0", "#444");
  const heading = useColorModeValue("gray.800", "#eeeeee");
  const font = useColorModeValue("gray.600", "gray.400");
  const inputbox=useColorModeValue("#ffffff", "#1c1c1c");
  const bg = useColorModeValue('gray.50', 'gray.700');

  const location = useLocation();
  const isHomepage = location.pathname === "/";
  
  //const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // const handleResize = () => {
    //   setIsMobile(window.innerWidth < 768);
    // };

    // // Add event listener
    // window.addEventListener("resize", handleResize);

    // // Call handler right away so state gets updated with initial window size
    // handleResize();

    // // Remove event listener on cleanup
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getData = async () => {
    // if (isMobile) {
    //   // Don't fetch data if on a mobile device
    //   return;
    // }

    try {
      let res = await axios.get(`${process.env.REACT_APP_API}/data/highlighted`);
      setData(res.data.data);
    } catch (err) {
      //console.log(err);
    }
  };

  useEffect(() => {
    getData();
  },[]);
  // }, [isMobile]); // Include isMobile as a dependency

 

  let [data, setData] = useState([]);

///brevo
  // const handleClick = async () => {
  //   setBtnLoading(true);
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!email || !emailRegex.test(email)) {
  //     notification("error", "Enter a valid email address");
  //     setBtnLoading(false);
  //     return;
  //   }
  //   try {      
  //     const response = await fetch('https://api.brevo.com/v3/contacts', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'api-key': process.env.REACT_APP_BREVO_API_KEY
  //         },
      
  //     body: JSON.stringify({
  //       email,
  //       listIds: [10], // Include the list ID in the request body to assign the contact to the list
  //     }),
  //   });
  //     notification("success", "Successfully subscribed");
  //     setBtnLoading(false);
  //   } catch (error) {
  //     notification("error", "Something went wrong");
  //     setBtnLoading(false);
  //   }
  // };

 //////////////////////////////////////////////////////////////////////// //beehiv
  const handleClick = async () => {
    setBtnLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      notification("error", "Enter a valid email address");
      setBtnLoading(false);
      return;
    }
    try {
      // Use your backend endpoint here. Update the URL to your deployed backend service
      const response = await axios.post(`${process.env.REACT_APP_API}/api/subscribe`, { email });

      // Assuming your backend sends a success message
      notification("success", response.data.message || "Successfully subscribed");
    } catch (error) {
      // Axios embeds the response in error.response
      notification("error", error.response?.data?.message || "Something went wrong");
    } finally {
      setBtnLoading(false);
      setEmail(""); // Optionally clear the email input after subscribing
    }
  };

  // const getData = async () => {
  //   try {
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_API}/data/highlighted`
  //     );

  //     setData(res.data.data);
  //   } catch (err) {
  //     //console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);
///////////////////////////////////////////////////////////////////


  // if (isMobile) {
  //   // Don't render anything if on a mobile device
  //   return null;
  // }

  return (
    <Box className={style.featured} mt="30px">
    {console.log(data)}
    {!isHomepage && (
        <>
          <Text color={heading} w="95%" fontSize="sm" lineHeight="24px" fontWeight="600" mt="30">
            Stay up-to-date with latest and greatest AI tools with our exclusive newsletter
          </Text>
          <Box>
            <Input
              mt="15px"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fontSize="14px"
              placeholder="Your email"
              border="1px"
              borderColor={borderColor}
              bg={inputbox}
            />
            {btnLoading ? (
              <Button
                isLoading
                mt="15px"
                w="100%"
                bg="#1D9BF0"
                color="#ffffff"
                fontSize="14px"
                fontWeight="600"
                loadingText={"Subscribe to the new..."}
              ></Button>
            ) : (
              <Button
                onClick={handleClick}
                mt="15px"
                w="100%"
                bg="#1D9BF0"
                color="#ffffff"
                fontSize="14px"
                fontWeight="600"
              >
                Subscribe to the newsletter
              </Button>
            )}

{/* <Link to="https://updates.aizones.io">
  <Text color="blue.500" textDecoration="underline" w="95%" fontSize="13px" lineHeight="24px" fontWeight="600" mt="5">
    Read the past issues
  </Text>
</Link> */}




        {/* /////////////////////////////////// */}
        </Box>
       






{/* //Highlighter  */}

      {/* <Divider
        border="1px"
        borderColor={borderColor}
        my="30px"
      />

      <Text
        textTransform="uppercase"
        fontWeight="700"
        lineHeight="1.4"
        fontSize="md"
        color={heading}
        as="h3"
        mb="20px"
        letterSpacing="0.5px"
      >
        Highlighted tools
      </Text>

      {data?.map((el, i) => (
        <Box 
          key={i} 
          mb="20px" 
          py="5px" 
          borderRadius="8px" 
         
          _hover={bg}
          transition="background 0.2s"
        >
          <Flex alignItems="center" gap="15px">
            <Link to={`/tool/${el.slug}`}>
              <Box 
                boxSize="48px"
                borderRadius="8px"
                overflow="hidden"
           
              >
                <Image 
                  boxSize="100%" 
                  alt={`${el.Title} logo`}
                  objectFit="contain"
                  src={`${el?.Logo}?height=48`} 
                />
              </Box>
            </Link>

            <Box flex="1">
              <Flex alignItems="center" justifyContent="space-between" mb="4px">
                <Link to={`/tool/${el.slug}`}>
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    color={heading}
                    lineHeight="1.4"
                    _hover={{ color: "#1D9BF0" }}
                    as="h1"
                  >
                    {el.Title}
                  </Text>
                </Link>
                <Link 
                  to={`${el?.URL}${el?.URL.includes('?') ? '&' : '?'}utm_source=aizones&utm_medium=referral`} 
                  target="_blank"
                >
                  <RiShareBoxFill 
                    size={16} 
                    aria-label="View Product Page"
                    style={{ color: '#1D9BF0' }}
                  />
                </Link>
              </Flex>
              <Link to={`/tool/${el.slug}`}>
                <Text
                  fontSize="13px"
                  lineHeight="1.5"
                  className={style.desc1}
                  color={font}
                  noOfLines={2}
                  as="h2"
                >
                  {el.Tagline}
                </Text>
              </Link>
            </Box>
          </Flex>
        </Box>
      ))}
        */}

      
<Divider
          border="1px"
          borderColor={borderColor}
          mt="30px"
          mb="30px"
        /> 

</>
      )}


    </Box>
  );
};

import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'



export const CategoryManage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  let [data, setData] = useState([])
  let [Title, setTitle] = useState("")
  let [Category, setCat] = useState([])
  let [edit, setedit] = useState("")





  const addData = async () => {
    const payload = {
      Title: Title,
      Category: Category
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_API}/cat/add`, payload

      ).then((res) => {
        getData()
        alert(res);
      })

    } catch (err) {

      //console.log(err);

    }

  }



  const getData = async () => {

    try {
      await axios.get(
        `${process.env.REACT_APP_API}/cat/get`,

      ).then((res) => {

        setData(res.data);
      })

    } catch (err) {

      //console.log(err);
    }
  };



  const deleteData = async (id) => {


    try {
      await axios.delete(
        `${process.env.REACT_APP_API}/cat/delete/${id}`

      ).then((res) => {
        getData()
        alert(res);
      })

    } catch (err) {

      //console.log(err);

    }

  }


  useEffect(() => {

    getData()


  }, [])


  const editData = async (id) => {
    const payload = {
      Title: Title,
      Category: Category
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_API}/cat/update/${id}`,
        payload

      ).then((res) => {
        getData()
        alert(res);
      })

    } catch (err) {

      //console.log(err);

    }

  }


  useEffect(() => {

    getData()


  }, [])


  return (
    <Box py="40px" w="80%" m="auto" justifyContent="center">
      {/* <h1>fajdklfj</h1> */}
      <Box boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px" p="20px">
        <FormControl>
          <FormLabel mt="10px">Category Type</FormLabel>
          <Input onChange={(e) => setTitle(e.target.value)} value={Title} placeholder=' Category Type' />

          <FormLabel mt="10px">Category</FormLabel>
          <Textarea
            value={Category.join('\n')}

            onChange={(e) => setCat(e.target.value.split('\n'))}
            placeholder="Category"
          />

          <Button onClick={addData} borderRadius="4px" mt="10px">Add</Button>
        </FormControl>
      </Box>


      <TableContainer mt="20px" color="white"
      >


        <Table variant='simple' >

          <Thead h="60px" bg="red" alignItems="center" >
            <Tr>
              <Th color="white">sr</Th>
              <Th color="white">Category Type</Th>
              <Th color="white">Category</Th>
              <Th color="white">Action</Th>
            </Tr>


          </Thead>

          <Tbody color="black" fontWeight="500" >

            {
              data?.map((el, i) => (
                <>

                  <Tr key={i}>
                    <Td>{i + 1}</Td>
                    <Td>{el.Title}</Td>
                    <Td>{el.Category.map((e) => (
                      <Text mt="5px">{e}</Text>
                    ))}</Td>
                    <Td display="flex" flexDirection="column" gap="20px"    >
                      <Button onClick={() => {
                        onOpen()
                        setedit(el._id)

                      }}>Edit</Button>
                      <Button onClick={() => deleteData(el._id)}>Delete</Button>

                    </Td>



                  </Tr>




                </>
              ))

            }


          </Tbody>

        </Table>
      </TableContainer>
      <Modal isCentered blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent color="#444" bg="#D9D9D9" borderRadius="0px" w="fit-content">

          <ModalBody >



            <Box>
              <Input
              mt="20px"
                type="text"
                placeholder="Enter collection name"
                name="playlist"
                border={"1px"}
                color="#444"
                borderRadius="1px"
                _placeholder={{ color: "#444" }}
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <Textarea
                row={3}
                mt="20px"
                type="text"
                border={"1px"}
                placeholder="Category "
                name="description"
                value={Category.join('\n')}
                 onChange={(e) => setCat(e.target.value.split('\n'))}
                color="#444"
                borderRadius="1px"
                _placeholder={{ color: "#444" }}

              />

              <Button color="white" mt="15px" onClick={editData} borderRadius="4px" bg="#1D9BF0" _hover={{ bg: "" }}>   Edit</Button>






            </Box>


          </ModalBody>


        </ModalContent>
      </Modal>

    </Box>
  )
}

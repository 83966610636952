import { Box, Divider, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState, useRef } from 'react'
import tool from "../../Style/Tool.module.css"
import style from "../../Style/Featured.module.css"
import { RiShareBoxFill } from 'react-icons/ri';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AdBanner from "../Home/adbanner.jsx"; 



export const Alternative = ({ el }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const font = useColorModeValue("gray.600", "gray.400");

    const getData = async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        const payload = { Category: el.Category };

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API}/data/get?page=1&limit=11`,  // Fetch only 10 items
                payload
            );
            console.log(res.data.data); // Log the response here
            setData(res.data.data);
            setHasMore(res.data.data.length === 11); // Check if exactly 10 items are returned

            setLoading(false);
        } catch (err) {
            setLoading(false);
            // Handle error
        }
    };

    useEffect(() => {
        getData();
    }, []); // Dependency array is empty to ensure it runs only once

    // sponsortool section

    // const [sponsoredTools, setSponsoredTools] = useState([]);
    // const currentAdIndex = useRef(0);
    // const TOOLS_BEFORE_AD = 9;
  
    // // Fetch Sponsored Tools data
    // useEffect(() => {
    //   const fetchSponsoredTools = async () => {
    //     try {
    //       const response = await axios.get(`${process.env.REACT_APP_API}/api/sponsoredtools`);
    //       setSponsoredTools(response.data);
    //     } catch (error) {
    //       console.error("Error fetching sponsored tools:", error);
    //     }
    //   };
  
    //   fetchSponsoredTools();
    // }, []);
  
    // // Get the next ad to display
    // const getNextAd = () => {
    //   if (sponsoredTools.length === 0) return null;
    //   const ad = sponsoredTools[currentAdIndex.current];
    //   currentAdIndex.current = (currentAdIndex.current + 1) % sponsoredTools.length;
    //   return ad;
    // };
   

    return (
        <>
            <Divider border="1px" my="16px" borderColor={useColorModeValue("#e0e0e0", "#444")} mt="20px" />
            
            {/* Adbanner section */}
            {/* <AdBanner  adTool={getNextAd()} /> */}
            <Box >
                <Box w="100%" m="auto" borderRadius="3px">
                    <Text as="h2"textTransform="uppercase" mb="20px" mt="20px" fontWeight="700" fontSize="16px"  lineHeight="20px">Alternative tools</Text>



                    <Box className={tool.rtool} w="90%" m="auto">

                        {
                            data.filter((fl) => fl.Title !== el.Title).map((ele, i) => (

                                <Box key={i}>

                                    <Flex alignItems="center" gap="10px"  >
                                        <Link to={`/tool/${ele?.slug}`}>
                                            <Box boxSize="55px">
                                                <Image alt={`${el.Title} logo`}  w="55px" height="100%" src={ele.Logo} />

                                            </Box>

                                        </Link>


                                        <Box>

                                            <Flex alignItems="center" justifyContent="space-between">
                                                <Link to={`/tool/${ele?.slug}`}  >
                                                    <Text fontSize="15px" as="h3" fontWeight="600" color="#22222" >{ele.Title}</Text>

                                                </Link>
                                                <Link to={ele.URL} target="_blank">
                                                    <RiShareBoxFill size={14} />
                                                </Link>

                                            </Flex>
                                            <Link to={`/tool/${ele?.slug}`}>
                                            <Text w="90%" fontSize="14px" lineHeight="20px" as="h4" color={font} className={style.desc}>{ele.Tagline}</Text>
                                            </Link>



                                        </Box>


                                    </Flex>

                                </Box>
                            ))
                        }

                    </Box>

                </Box>
            </Box>





            <Divider border="1px" borderColor={useColorModeValue("#e0e0e0", "#444")} mt="20px" />

        </>
    )
}

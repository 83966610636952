import { SearchIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorMode,
  useColorModeValue,Button,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import style from "../Style/Collection.module.css";
// import style from "../Style/Featured.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
// import textStyle from "../../Style/List.module.css";
// import LoadingAnimation from '../LoadingAnimation';

export const CurtedCollection_home = () => {
  let [data, setData] = useState([]);
  let [page, setPage] = useState(1);

  const { colorMode, toggleColorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const borderColor = useColorModeValue("#e0e0e0", "#444");
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');

  const font = useColorModeValue("gray.600", "gray.400");
  const heading = useColorModeValue("gray.800", "#eeeeee");

  const getData = async () => {
    setLoading(true);
  
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/space/get/all_home?&page=${page}`
      );
  
      const fetchedData = res.data.data.space;
      console.log('Fetched Data:', fetchedData);
  
      const newData = fetchedData.filter(fetchedItem => 
        !data.some(existingItem => existingItem._id === fetchedItem._id)
      );
  
      console.log('New Data (filtered):', newData);
      setData(prevData => [...prevData, ...newData]);
      setHasMore(fetchedData.length > 0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    getData();
  }, [page]);

  const slicedData = isDesktop ? data.slice(0, 3) : data.slice(0, 3);



  // if (loading) {
  //   return (
  //     <div style={{ marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       <LoadingAnimation size="xl" />
  //     </div>
  //   );
  // }

  const bordercolor = useColorModeValue("#e0e0e0e6" , "#333333"); 

  return (
    <Box mt={7} fontFamily="'Lato', Arial, Helvetica, sans-serif" maxW="1400px" mx="auto" >
      <Heading 
        as="h2" 
        textAlign="Left" 
        fontSize={{base: "xl", md: "xl"}} 
        color='blue.400' 
        my={3}
        fontWeight="700"
      >
        Curated Collections
      </Heading>
      <Text as="h3" fontSize={{base: "14px", md: "16px"}} color={font} mb={5}>
        Explore collections of AI tools created by other users.
      </Text>

      <Grid
        width="100%"
        gridTemplateColumns={{ base: "repeat(1,1fr)", md: "repeat(2,1fr)", lg: "repeat(3,1fr)" }}
        gap={{base: "4", md: "6"}}
        marginTop={5}
      >
        {slicedData.map((el, i) => (
          <Link to={`/collection/curated-collection/${el.slug}`} key={i}>
            <Flex 
              width="100%" 
              gap={4}
              p={3}
              borderRadius="lg"
              transition="all 0.3s ease"
              _hover={{ 
                bg: hoverBgColor,
                transform: 'translateY(-2px)',
                "& .hover-grid": {
                  borderColor: 'blue.400',
                  boxShadow: '0 0 12px rgba(66, 164, 255, 0.3)'
                }
              }}
            >
              <Grid
                className="hover-grid"
                width="100px"
                gridTemplateColumns="repeat(2,1fr)"
                gridTemplateRows="repeat(2, 1fr)"
                gap={2}
                backgroundColor={colorMode === "light" ? "#DAE7FA" : "#2f3f48"}
                borderRadius="md"
                padding={3}
                border="1px solid"
                borderColor={bordercolor}
                height="100px"
                transition="all 0.3s ease"
              >
                {el?.tool[0]?.logoURL ? (
                  <Image
                    src={`${el?.tool[0].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`1st Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530661/genetic-data.svg"}
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[1]?.logoURL ? (
                  <Image
                    src={`${el?.tool[1].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`2nd Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530439/api-interface.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[2]?.logoURL ? (
                  <Image
                    src={`${el?.tool[2].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`3rd Tool's logo from collection`} 
                  />
                ) : (
                  // <Image
                  //   src={
                  //     "https://www.svgrepo.com/show/530401/table-of-contents.svg"
                  //   }
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                )}
                {el?.tool[3]?.logoURL ? (
                  <Image
                    src={`${el?.tool[3].logoURL}?height=50`}
                    borderRadius="4px"
                    width={"65px"}
                    height={"auto"}
                    alt={`4th Tool's logo from collection`} 
                  />
                ) : 
                  // <Image
                  //   src={"https://www.svgrepo.com/show/530225/cell-phone.svg"}
                  //   borderRadius="4px"
                  //   width={"65px"}
                  //   height={"auto"}
                  // />
                  ""
                }
       
              </Grid>

              <Flex
                flex={1}
                flexDirection="column"
                gap={2}
              >
                <Text
                  fontSize={{base: "16px", md: "17px"}}
                  fontWeight="600"
                  color={heading}
                  lineHeight="1.4"
                >
                  {el?.space_name.length > 30
                    ? `${el?.space_name.substring(0, 28)}...`
                    : el?.space_name}
                </Text>

                <Flex
                  alignItems="center"
                  gap={2}
                  mt={1}
                >
                  <Image 
                    boxSize="26px"
                    borderRadius="full"
                    loading="lazy"
                    alt="Profile picture"
                    src={el?.userID?.image}
                  />
                  <Text fontSize="13px" fontWeight="500">
                    {`${el?.userID?.name}`.length > 8
                      ? `${el?.userID?.name}`.substring(0, 7) + ".."
                      : `${el?.userID?.name}`}
                  </Text>
                  <Text color="blue.400" mx={1}>•</Text>
                  <Text fontSize="13px" color="blue.400" fontWeight="500">
                    {el?.tool.length} tools
                  </Text>
                </Flex>

                <Text 
                  color={font} 
                  fontSize="14px"
                  mt={1}
                  lineHeight="1.5"
                >
                  {`${el?.description}`.length > 50
                    ? `${el?.description}`.substring(0, 45) + "..."
                    : `${el?.description}`}
                </Text>
              </Flex>
            </Flex>
          </Link>
        ))}
      </Grid>

      <Center py={6}>
        <Link to="/collection">
          <Button
            
          >
            View All Collections
          </Button>
        </Link>
      </Center>
    </Box>
  );
};

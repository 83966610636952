import React, { useState } from 'react';
import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, Text, InputGroup, Input } from '@chakra-ui/react';
import { BsChatText } from 'react-icons/bs';
import axios from 'axios';
import notification from "../Toast";

const SupportModal = ({ userData, handleActionButtonClick, onOpen, isOpen, onClose, boxShadowColor }) => {
  const [message, setMessage] = useState('');

  const sendMessage = () => {
    const payload = {
      message: message,
    };
    const token = userData.data;
    axios
      .post(`${process.env.REACT_APP_API}/message/add`, payload, {
        headers: { token },
      })
      .then((res) => {
        notification("success", res.data.msg);
        onClose();
      });
  };


  return (
    <>
      <Box
        cursor="pointer"
        onClick={() => {
          if (!userData) {
            handleActionButtonClick();
            return;
          }
          onOpen();
        }}
        p="10px"
        position="fixed"
        bottom="8%"
        right="2%"
        zIndex="200"
        bg="black"
        borderRadius="10px"
        boxShadow={`0px 4px 10px ${boxShadowColor}`}
      >
        <BsChatText color="white" size={25} />
      </Box>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="350px" color="#444" bg="#D9D9D9" borderRadius="0px">
          <ModalHeader
            bg="#1D9BF0"
            color="white"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="700"
          >
            How can we help ?
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody fontSize="13px" fontWeight="400" lineHeight="16px">
            <Flex
              h="390px"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <Text fontSize="13px" lineHeight="24px" fontWeight="400">
                  {" "}
                  Welcome to our Support Page! We value your feedback and are here
                  to assist you. If you have any questions, concerns, or
                  suggestions regarding our platform or experience any issues, we
                  encourage you to reach out to us
                </Text>

                <Text fontSize="13px" marginTop={"30px"} lineHeight="24px" fontWeight="400">
                  {" "}
                  Contact: hello@aizones.io
                </Text>
              </Box>

              <InputGroup>
                <Input
                  onChange={(e) => setMessage(e.target.value)}
                  border="1px solid #000"
                  borderRadius="5px"
                  mt={15}
                  _placeholder={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#000",
                  }}
                  placeholder="send a message"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
              </InputGroup>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SupportModal;

